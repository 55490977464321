import { RootState } from '../store';

const getRadiusFilterValue = (state: RootState) => state.studios.radiusFilterValue;

const getSearchQuery = (state: RootState) => state.studios.query;

const getVisibleStudios = (state: RootState) => state.studios.visibleStudios;

const getCategories = (state: RootState) => state.studios.categories;

const getEquipmentAndServices = (state: RootState) => state.studios.equipmentAndServices;

const getHover = (state: RootState) => state.studios.hover;

const getSelected = (state: RootState) => state.studios.selected;

const getFilter = (state: RootState) => state.studios.filter;

const getSelectedStudioModalOpen = (state: RootState) => state.studios.selectedStudioModalOpen;

const getInputInformation = (state: RootState) => state.studios.inputInformation;

const getStudioGroups = (state: RootState) => state.studios.studioGroups;

const getSelectedStudios = (state: RootState) => state.studios.selectedStudios;
const getHighlightedStudios = (state: RootState) => state.studios.highlightedStudios;
const getSort = (state: RootState) => state.studios.sort;

export const StudioSelectors = {
  getRadiusFilterValue,
  getSort,
  getSearchQuery,
  getVisibleStudios,
  getHover,
  getSelected,
  getFilter,
  getCategories,
  getSelectedStudioModalOpen,
  getInputInformation,
  getStudioGroups,
  getSelectedStudios,
  getHighlightedStudios,
  getEquipmentAndServices,
};
