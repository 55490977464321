import {
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import sortBy from 'lodash.sortby';
import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { membershipImageBasePath, UserMemberships } from '../../models/user_membership';
import { FormattedMessage, useIntl } from 'react-intl';
import { StudioActions } from '../../store/studio';
import {
  ActiveFilterButton,
  ActiveResetButton,
  basicItemBackground,
  bestItemBackground,
  CategoryIcon,
  CheckInIcon,
  CheckInInfoButton,
  CloseButton,
  Container,
  CtaContainer,
  CtaPlaceholder,
  Divider,
  FilterButton,
  FilterTitle,
  InputContainer,
  listStyles,
  MembershipContainer,
  MembershipIcon,
  MembershipInfoButton,
  MembershipInfoContainer,
  MembershipMobileItems,
  MembershipMobileItemsTitle,
  MobileMembershipContainer,
  Modal,
  proItemBackground,
  ResetButton,
  SelectFilterTitle,
  StyledDropdownIcon,
  StyledFormControl,
  StyledMembershipSelect,
} from './FilterModal.styles';
import filtersModalIcon from '../../assets/icons/atoms-cta-close-blue.svg';
import dropdownIcon from '../../assets/icons/atoms-icons-dropdown.svg';
import infoIcon from '../../assets/icons/combined-shape-3.svg';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { checkinImageBasePath, getFilterToStateFilter } from '../../store/studio/studio.utils';
import { CheckInOption } from '../../models/CheckIn';
import { getResolvedCategories, getResolvedCheckIns, getSelectValue } from './FilterModal.utils';
import { CheckInInformationModal } from './CheckInInformationModal';
import { MembershipModal } from './MembershipModal';
import { AutocompleteOptionType } from '../../models/AutocompleteOption';
import { initialState } from '../../store/studio/initialState';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import useAllVisibleStudios from '../../hooks/useAllVisibleStudios';

export const FilterModal = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const listClasses = listStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const [categories, setCategories] = useState<number[]>([]);
  const [checkInsAndServices, setCheckInsAndServices] = useState<CheckInOption[]>([]);
  const [memberships, setMemberships] = useState<number[]>([]);
  const allCategories = useSelector(StudioSelectors.getCategories);
  const allEquipmentAndServices = useSelector(StudioSelectors.getEquipmentAndServices);
  const filter = useSelector(StudioSelectors.getFilter);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const [isCheckInInformationModalOpen, setIsCheckInInformationModalOpen] = useState(false);
  const [isMembershipModalOpen, setIsMembershipModalOpen] = useState(false);
  const [equipmentAndServicesOptions, setEquipmentAndServicesOptions] = useState<string[]>([]);
  const { data: allVisibleStudios = [] } = useAllVisibleStudios();

  const gym = useMemo(() => {
    if (allCategories) {
      return allCategories.find(category => category.id === 197);
    }
  }, [allCategories]);
  const swimming = useMemo(() => {
    if (allCategories) {
      return allCategories.find(category => category.id === 206);
    }
  }, [allCategories]);
  const yoga = useMemo(() => {
    if (allCategories) {
      return allCategories.find(category => category.id === 213);
    }
  }, [allCategories]);

  const handleCheckInModal = () => {
    setIsCheckInInformationModalOpen(!isCheckInInformationModalOpen);
  };

  const handleMembershipModal = () => {
    setIsMembershipModalOpen(!isMembershipModalOpen);
  };
  const handleCategories = async (event: SelectChangeEvent<unknown>) => {
    const eventValues = event.target.value as number[];
    const categories = eventValues.filter(value => value > 0);

    setCategories(categories);
  };

  const handleCheckInsAndServices = (event: SelectChangeEvent<CheckInOption[]>) => {
    const checkInsAndServices = event.target.value as CheckInOption[];
    setCheckInsAndServices(checkInsAndServices);
  };

  const handleMemberships = (event: SelectChangeEvent<unknown>, b: any) => {
    const updatedMemberships = [b.props.value];

    setMemberships(
      JSON.stringify(updatedMemberships) === JSON.stringify(memberships) ? [] : updatedMemberships,
    );
  };

  const applyFilter = () => {
    dispatch(
      StudioActions.applyFilters({
        checkInsAndServices,
        categories,
        memberships,
        equipmentAndServices: equipmentAndServicesOptions,
      }),
    );

    const filterState = getFilterToStateFilter({
      checkInsAndServices,
      categories,
      memberships,
      equipmentAndServices: equipmentAndServicesOptions,
    });

    dispatch(
      StudioActions.filterVisibleStudioGroupItems({
        inputInformation,
        type: AutocompleteOptionType.Prediction,
        filter: filterState,
        radius,
        allVisibleStudios,
      }),
    );

    closeModal();
  };

  const resetFilters = () => {
    dispatch(StudioActions.resetFilters());
    dispatch(
      StudioActions.filterVisibleStudioGroupItems({
        inputInformation,
        type: AutocompleteOptionType.Prediction,
        filter: initialState.filter,
        radius,
        allVisibleStudios,
      }),
    );
    closeModal();
  };

  const handleClose = () => {
    closeModal();
  };

  const handleMobileMemberships = (event: BaseSyntheticEvent) => {
    const membership = event.currentTarget.value as number;
    setMemberships([membership]);
  };

  useEffect(() => {
    const hansefitCard = filter.hansefitCard;
    const checkInCard = filter.checkInCard;
    const checkInApp = filter.checkInApp;
    const twogether = filter.servicePackages.find(service => service === 18);

    const basic = filter.servicePackages.find(service => service === UserMemberships.basic);
    const pro = filter.servicePackages.find(service => service === UserMemberships.pro);
    const best = filter.servicePackages.find(service => service === UserMemberships.best);

    const checkInsAndServices = [];
    const memberships = [];

    if (hansefitCard) {
      checkInsAndServices.push(CheckInOption.Issuing);
    }

    if (checkInApp) {
      checkInsAndServices.push(CheckInOption.App);
    }

    if (checkInCard) {
      checkInsAndServices.push(CheckInOption.Card);
    }

    if (twogether) {
      checkInsAndServices.push(CheckInOption.Twogether);
    }

    if (basic) {
      memberships.push(UserMemberships.basic);
    }

    if (pro) {
      memberships.push(UserMemberships.pro);
    }

    if (best) {
      memberships.push(UserMemberships.best);
    }

    setCheckInsAndServices(checkInsAndServices);
    setCategories(filter.categories);
    setMemberships(memberships);
    setEquipmentAndServicesOptions(filter.equipmentAndServices);
  }, [filter]);

  return (
    <>
      <Modal>
        <CloseButton onClick={handleClose}>
          <img src={filtersModalIcon} alt='' />
        </CloseButton>

        <Container>
          <FilterTitle>
            {formatMessage({
              id: 'filters.header',
              defaultMessage: 'Filter options',
            })}
          </FilterTitle>

          <InputContainer>
            <StyledFormControl size='small' variant='outlined'>
              <InputLabel id='select-partner-category'>
                {formatMessage({
                  id: 'filters.partner_category.label',
                  defaultMessage: 'Partner category',
                })}
              </InputLabel>
              <Select
                labelId='select-partner-category'
                multiple
                value={categories}
                IconComponent={props => (
                  <StyledDropdownIcon className={props.className} src={dropdownIcon} />
                )}
                input={
                  <OutlinedInput
                    label={formatMessage({
                      id: 'filters.partner_category.label',
                      defaultMessage: 'Partner category',
                    })}
                  />
                }
                renderValue={value =>
                  getSelectValue(
                    getResolvedCategories(value, allCategories),
                    28,
                    formatMessage({
                      id: 'filters.additional',
                      defaultMessage: 'Additional',
                    }),
                  )
                }
                onChange={handleCategories}
                MenuProps={{
                  classes: {
                    root: listClasses.root,
                    list: listClasses.listCategory,
                  },
                  disableAutoFocusItem: true,
                }}>
                <SelectFilterTitle>
                  <FormattedMessage
                    id='filters.select_category_title'
                    defaultMessage='Show only studios that correspond to the following categories:'
                  />
                </SelectFilterTitle>

                {gym && (
                  <MenuItem key={gym.shortDescription + '_'} value={gym.id}>
                    <CategoryIcon
                      src={
                        categories.indexOf(gym.id) !== -1
                          ? gym.iconUrlSelected!
                          : gym.iconUrlCircle!
                      }
                      alt=''
                    />
                    {gym.shortDescription}
                  </MenuItem>
                )}

                {swimming && (
                  <MenuItem key={swimming.shortDescription + '_'} value={swimming.id}>
                    <CategoryIcon
                      src={
                        categories.indexOf(swimming.id) !== -1
                          ? swimming.iconUrlSelected!
                          : swimming.iconUrlCircle!
                      }
                      alt=''
                    />
                    {swimming.shortDescription}
                  </MenuItem>
                )}

                {yoga && (
                  <MenuItem key={yoga.shortDescription + '_'} value={yoga.id}>
                    <CategoryIcon
                      src={
                        categories.indexOf(yoga.id) !== -1
                          ? yoga.iconUrlSelected!
                          : yoga.iconUrlCircle!
                      }
                      alt=''
                    />
                    {yoga.shortDescription}
                  </MenuItem>
                )}
                {(gym || swimming || yoga) && <Divider />}
                {sortBy(allCategories, 'shortDescription').map(category => (
                  <MenuItem key={category.shortDescription} value={category.id}>
                    <CategoryIcon
                      src={
                        categories.indexOf(category.id) !== -1
                          ? category.iconUrlSelected!
                          : category.iconUrlCircle!
                      }
                      alt=''
                    />
                    {category.shortDescription}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <StyledFormControl size='small'>
              <InputLabel id='select-check-in'>
                {formatMessage({
                  id: 'filters.check_in.label',
                  defaultMessage: 'Check-in / services',
                })}
              </InputLabel>
              <Select
                labelId='select-check-in'
                multiple
                renderValue={value =>
                  getSelectValue(
                    getResolvedCheckIns(value, formatMessage),
                    32,
                    formatMessage({
                      id: 'filters.additional',
                      defaultMessage: 'Additional',
                    }),
                  )
                }
                value={checkInsAndServices}
                IconComponent={props => (
                  <StyledDropdownIcon className={props.className} src={dropdownIcon} />
                )}
                label={formatMessage({
                  id: 'filters.check_in.label',
                  defaultMessage: 'Check-in / services',
                })}
                onChange={handleCheckInsAndServices}
                MenuProps={{
                  classes: {
                    root: listClasses.rootCheckIn,
                    list: listClasses.listCheckIn,
                  },
                }}>
                <SelectFilterTitle>
                  <FormattedMessage
                    id='filters.select_check_in_title'
                    defaultMessage='Show only studios that correspond to the following check-in options and services:'
                  />
                </SelectFilterTitle>

                <MenuItem value={CheckInOption.App}>
                  <CheckInIcon src={checkinImageBasePath + 'checkin-app.svg'} alt='checkIn' />
                  {formatMessage({
                    id: 'check_in.app',
                    defaultMessage: 'Check-in with app',
                  })}
                </MenuItem>

                {/* <MenuItem value={CheckInOption.Card}>
                  <CheckInIcon src={checkinImageBasePath + 'checkin-card.svg'} alt={'checkIn'} />
                  {formatMessage({
                    id: 'check_in.card',
                    defaultMessage: 'Check-in with card',
                  })}
                </MenuItem> */}

                {/* <MenuItem value={CheckInOption.Issuing}>
                  <CheckInIcon src={checkinImageBasePath + 'issuing-card.svg'} alt={'checkIn'} />
                  {formatMessage({
                    id: 'check_in.issuing',
                    defaultMessage: 'Issuing of membership cards',
                  })}
                </MenuItem> */}

                <MenuItem value={CheckInOption.Twogether}>
                  <CheckInIcon src={checkinImageBasePath + 'twogether.svg'} alt='checkIn' />
                  {formatMessage({
                    id: 'check_in.twogether',
                    defaultMessage: 'Twogether training program',
                  })}
                </MenuItem>
                <CheckInInfoButton onClick={handleCheckInModal}>
                  <img src={infoIcon} alt='' />
                </CheckInInfoButton>
              </Select>
            </StyledFormControl>
            {isLarge ? (
              <div>
                <MembershipMobileItemsTitle>
                  <FormattedMessage
                    id='filters.select_membership_mobile_title'
                    defaultMessage='Select membership:'
                  />
                </MembershipMobileItemsTitle>

                <MembershipMobileItems>
                  <MenuItem value={UserMemberships.basic} onClick={handleMobileMemberships}>
                    <MobileMembershipContainer
                      isSelected={memberships.includes(UserMemberships.basic)}
                      backgroundColor={basicItemBackground}>
                      <MembershipIcon
                        src={membershipImageBasePath + 'hf_basic.png'}
                        alt='Membership'
                      />
                      Basic
                    </MobileMembershipContainer>
                  </MenuItem>
                  <MenuItem value={UserMemberships.pro} onClick={handleMobileMemberships}>
                    <MobileMembershipContainer
                      isSelected={memberships.includes(UserMemberships.pro)}
                      backgroundColor={proItemBackground}>
                      <MembershipIcon
                        src={membershipImageBasePath + 'hf_pro.png'}
                        alt='Membership'
                      />
                      Pro
                    </MobileMembershipContainer>
                  </MenuItem>
                  <MenuItem value={UserMemberships.best} onClick={handleMobileMemberships}>
                    <MobileMembershipContainer
                      isSelected={memberships.includes(UserMemberships.best)}
                      backgroundColor={bestItemBackground}>
                      <MembershipIcon
                        src={membershipImageBasePath + 'hf_best.png'}
                        alt='Membership'
                      />
                      Best
                    </MobileMembershipContainer>
                  </MenuItem>
                </MembershipMobileItems>

                <MembershipInfoContainer onClick={handleMembershipModal}>
                  <img alt='' src={infoIcon} />
                  <MembershipInfoButton>
                    <FormattedMessage
                      id='filters.membership_info'
                      defaultMessage='What are Hansefit Memberships?'
                    />
                  </MembershipInfoButton>
                </MembershipInfoContainer>
              </div>
            ) : (
              <StyledFormControl size='small'>
                <InputLabel id='select-membership'>
                  {formatMessage({
                    id: 'filters.membership.label',
                    defaultMessage: 'Membership',
                  })}
                </InputLabel>
                <StyledMembershipSelect
                  labelId='select-membership'
                  multiple
                  value={memberships}
                  IconComponent={props => (
                    <StyledDropdownIcon className={props.className} src={dropdownIcon} />
                  )}
                  label={formatMessage({
                    id: 'filters.membership.label',
                    defaultMessage: 'Membership',
                  })}
                  onChange={handleMemberships}
                  renderValue={value =>
                    (value as number[]).map(value => UserMemberships[value]).join(', ')
                  }
                  MenuProps={{
                    classes: {
                      root: listClasses.rootMembership,
                      list: listClasses.listMembership,
                    },
                  }}>
                  <SelectFilterTitle>
                    <FormattedMessage
                      id='filters.select_membership_title'
                      defaultMessage='Show only studios that correspond to the following memberships:'
                    />
                  </SelectFilterTitle>

                  <MenuItem value={UserMemberships.basic}>
                    <MembershipContainer>
                      <MembershipIcon
                        src={membershipImageBasePath + 'hf_basic.png'}
                        alt='Membership'
                      />
                      Basic
                    </MembershipContainer>
                  </MenuItem>

                  <MenuItem value={UserMemberships.pro}>
                    <MembershipContainer>
                      <MembershipIcon
                        src={membershipImageBasePath + 'hf_pro.png'}
                        alt='Membership'
                      />
                      Pro
                    </MembershipContainer>
                  </MenuItem>

                  <MenuItem value={UserMemberships.best}>
                    <MembershipContainer>
                      <MembershipIcon
                        src={membershipImageBasePath + 'hf_best.png'}
                        alt='Membership'
                      />
                      Best
                    </MembershipContainer>
                  </MenuItem>
                  <MembershipInfoContainer>
                    <MembershipInfoButton onClick={handleMembershipModal}>
                      <img alt='' src={infoIcon} />
                      <FormattedMessage
                        id='filters.membership_info'
                        defaultMessage='What are Hansefit Memberships?'
                      />
                    </MembershipInfoButton>
                  </MembershipInfoContainer>
                </StyledMembershipSelect>
              </StyledFormControl>
            )}

            <StyledFormControl size='small'>
              <InputLabel id='select-equipment-and-services'>
                {formatMessage({
                  id: 'filters.equipment_and_services.label',
                  defaultMessage: 'Equipment and services',
                })}
              </InputLabel>
              <Select
                labelId='select-equipment-and-services'
                multiple
                renderValue={value => getSelectValue(value, 32, '')}
                value={equipmentAndServicesOptions}
                IconComponent={props => (
                  <StyledDropdownIcon className={props.className} src={dropdownIcon} />
                )}
                label={formatMessage({
                  id: 'filters.equipment_and_services.label',
                  defaultMessage: 'Equipment and services',
                })}
                onChange={(event: SelectChangeEvent<string[]>) => {
                  setEquipmentAndServicesOptions(event.target.value as string[]);
                }}
                MenuProps={{
                  classes: {
                    root: listClasses.root,
                    list: listClasses.listCategory,
                  },
                }}>
                <SelectFilterTitle>
                  <FormattedMessage
                    id='filters.select_equipment_and_services_title'
                    defaultMessage='Show only studios that correspond to the following equipment and services:'
                  />
                </SelectFilterTitle>

                {allEquipmentAndServices.map(equipmentAndService => (
                  <MenuItem key={equipmentAndService} value={equipmentAndService}>
                    <HomeRepairServiceOutlinedIcon
                      style={{
                        marginRight: '10px',
                        color: equipmentAndServicesOptions.includes(equipmentAndService)
                          ? '#e52450'
                          : 'inherit',
                      }}
                    />
                    {equipmentAndService}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </InputContainer>
          <CtaContainer>
            {isLarge ? (
              categories.length > 0 || checkInsAndServices.length > 0 || memberships.length > 0 ? (
                <>
                  <ActiveResetButton variant='outlined' color='primary' onClick={resetFilters}>
                    {formatMessage({
                      id: 'filters.reset',
                      defaultMessage: 'Reset filter',
                    })}
                  </ActiveResetButton>
                  <ActiveFilterButton variant='contained' onClick={applyFilter}>
                    {formatMessage({
                      id: 'filters.submit',
                      defaultMessage: 'Set filter',
                    })}
                  </ActiveFilterButton>
                </>
              ) : (
                <>
                  <ResetButton variant='text' color='primary' onClick={resetFilters}>
                    {formatMessage({
                      id: 'filters.reset',
                      defaultMessage: 'Reset filter',
                    })}
                  </ResetButton>
                  <FilterButton variant='contained' onClick={applyFilter}>
                    {formatMessage({
                      id: 'filters.submit',
                      defaultMessage: 'Set filter',
                    })}
                  </FilterButton>
                </>
              )
            ) : (
              <>
                {(categories.length > 0 ||
                  checkInsAndServices.length > 0 ||
                  memberships.length > 0) && (
                  <ResetButton variant='outlined' color='primary' onClick={resetFilters}>
                    {formatMessage({
                      id: 'filters.reset',
                      defaultMessage: 'Reset filter',
                    })}
                  </ResetButton>
                )}
                <CtaPlaceholder />
                <FilterButton variant='contained' onClick={applyFilter}>
                  {formatMessage({
                    id: 'filters.submit',
                    defaultMessage: 'Set filter',
                  })}
                </FilterButton>
              </>
            )}
          </CtaContainer>
        </Container>
      </Modal>
      {isCheckInInformationModalOpen && (
        <CheckInInformationModal handleClose={handleCheckInModal} />
      )}
      {isMembershipModalOpen && <MembershipModal handleClose={handleMembershipModal} />}
    </>
  );
};
