import { StudioModel, StudioModelFiltered } from '../../models/StudioModel';
import { Category } from '../../models/Category';

export type EquipmentAndServices = string;
export enum StudioSortType {
  Distance,
  Alphabetical,
}

export interface StudioState {
  visibleStudios: StudioModel[];
  inputInformation: InputInformation[];
  studioGroups: StudioGroupItem[];
  categories: Category[];
  query: string;
  equipmentAndServices: EquipmentAndServices[];

  radiusFilterValue: number;
  hover: string | null;
  selected: StudioModelFiltered | null;
  selectedStudios: string[];
  highlightedStudios: string[];
  filter: StudioFilter;
  selectedStudioModalOpen: boolean;
  sort: StudioSortType;
}

export interface StudioFilter {
  categories: number[];
  servicePackages: number[];
  checkInCard: boolean;
  checkInApp: boolean;
  hansefitCard: boolean;
  equipmentAndServices: EquipmentAndServices[];
}

export interface StudioGroupItem {
  inputInformationId: string;
  fileId: string;
  visibleStudios: StudioModelFiltered[];
}

export interface InputInformation {
  id: string;
  name: string | number;
  lat: number;
  lng: number;
  placeId: string;
  fileId: string;
  filename: string;
  predictionTypes?: string[];
}

export interface PlaceDetail {
  id: string;
  lat: number;
  lng: number;
}

export type InputFilters = {
  memberships: number[];
  checkInsAndServices: string[];
  categories: number[];
  equipmentAndServices: EquipmentAndServices[];
};
