import { Box, IconButton, styled, Typography } from '@mui/material';

export const ModalContainer = styled(Box)`
  width: 400px;
  top: 50%;
  left: 50%;
  max-height: 66%;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 38px 60px 50px 40px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;

  ${props => props.theme.breakpoints.down('lg')} {
    width: 80%;
    max-height: 80%;
    padding: 38px 30px 50px 20px;
  }
`;

export const StyledHeader = styled(Typography)`
  color: #1f2126;
  font-family: NutmegHeadline-Ultra;
  font-size: 16px;
  text-transform: uppercase;
`;

export const StyledDescription = styled(Typography)`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #1f2126;
  margin-top: 15px;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 14px;
  top: 14px;

  ${props => props.theme.breakpoints.down('lg')} {
    right: 0px;
  }
`;
