import { styled, Typography } from '@mui/material';

export const NoResultsContainer = styled('div')`
  border-top: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 122px 35px 0 35px;
  
  ${props => props.theme.breakpoints.down('lg')} {
    height: calc(100vh - 274px);
    padding-top: 60px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    height: calc(100vh - 262px);
  }
`;

export const Container = styled('div')`
  border-radius: 8px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 50px;
  align-self: stretch;
`;

export const Icon = styled('img')`
  text-align: center;
`;

export const NoResultsTitle = styled(Typography)`
  display: flex;
  color: #1f2126;
  font-family: Roboto;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
`;

export const NoResultsText = styled(Typography)`
  display: flex;
  color: #1f2126;
  font-family: Roboto-Regular;
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
`;
