import { Text, View, Image } from '@react-pdf/renderer';
import logoOnlineCourses from '../../../../assets/icons/sp_app_live_online_courses.png';
import { Styles } from './LiveCourses.styles';

export interface LiveCoursesProps {
  infoHeader?: string;
  infoText?: string;
}

export const LiveCourses = ({ infoHeader, infoText }: LiveCoursesProps) => {
  return (
    <View style={Styles.container}>
      <View style={Styles.firstSection}>
        <View style={Styles.infoContainer}>
          {infoHeader && <Text style={Styles.infoHeader}>{infoHeader}</Text>}
          {infoText && <Text style={Styles.liveInfoText}>{infoText}</Text>}
        </View>
        <View style={Styles.imageContainer}>
          <Image src={logoOnlineCourses} style={Styles.appLogo} />
        </View>
      </View>
    </View>
  );
};
