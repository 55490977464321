import { PdfTableItem } from '../../interfaces';

export const getGroupedTableItems = (arr: PdfTableItem[]): PdfTableItem[][] => {
  const groupedArrays: PdfTableItem[][] = [];
  let currentIndex = 0;

  while (currentIndex < arr.length) {
    const chunkSize = currentIndex === 0 ? 8 : 18;
    groupedArrays.push(arr.slice(currentIndex, currentIndex + chunkSize));
    currentIndex += chunkSize;
  }

  return groupedArrays;
};
