import { IconButton, Link, styled } from '@mui/material';

export const StudioModalContainer = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 16px;
  left: 15px;
  width: calc(100% - 70px);
  background-color: white;
  color: #1f2126;
  padding: 20px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  gap: 10px;
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;
export const LeftColumn = styled('div')``;
export const RightColumn = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
`;

export const CategoryImage = styled('img')`
  width: 48px;
  height: 48px;
  object-fit: cover;
`;

export const DetailsButton = styled(Link)`
  background-color: #003bb3;
  border-radius: 32px;
  font-family: Roboto;
  font-weight: 500;
  padding: 5px 16px;
  min-width: fit-content;
  box-shadow: none;
  width: 100%;
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 12px;
`;

export const StudioName = styled('div')`
  font-family: Roboto-Medium;
  line-height: 1.22;
  letter-spacing: 0.23px;
  font-size: 18px;
  color: #1f2126;
`;
export const Address = styled('div')`
  display: flex;
  font-family: Roboto-Regular;
  line-height: 1.4;
  letter-spacing: 0.09px;
  font-size: 10px;
  color: #1f2126;
`;

export const CloseButton = styled(IconButton)`
  align-self: flex-start;

  padding: 0;
`;
