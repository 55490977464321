import { StudioGroupItem } from '../store/studio/interfaces';

export const hasAnyResult = (groups: StudioGroupItem[]): boolean => {
  if (groups.length > 0) {
    return groups.map(group => group.visibleStudios.length).reduce((a, b) => a + b) > 0;
  } else {
    return false;
  }
};

export const isLessThan30Days = (time: number): boolean => {
  const currentDate = new Date();
  const currentTimeInMilliseconds = currentDate.getTime();

  const days = (currentTimeInMilliseconds - time) / (1000 * 60 * 60 * 24);

  return days <= 30;
};

export const arrayUnique = <T>(array: T[]): T[] =>
  array.filter((value, index, self) => self.indexOf(value) === index);
