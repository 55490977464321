import axios from 'axios';
import { StudioModelFiltered, StudioModelRaw } from '../../../../models/StudioModel';
import { filterAndMapStudios } from '../../../../utils/filterAndMapStudios';
import { useQuery } from '@tanstack/react-query';

const getPartnerList = async (language: 'de' | 'en'): Promise<StudioModelFiltered[]> => {
  const endpoint = `${process.env.REACT_APP_API_PARTNER_LIST_ENDPOINT}static/api/partners?language=${language}`;

  const response = await axios.get<{ data?: StudioModelRaw[] }[]>(endpoint);

  const data = response.data?.[0]?.data ?? [];
  const filteredAndMappedStudios = filterAndMapStudios(data);

  return filteredAndMappedStudios;
};

export const usePartnerListQuery = ({ language }: { language: 'de' | 'en' }) => {
  const query = useQuery({
    queryKey: ['updatedFilteredPartnerList', language],
    queryFn: () => getPartnerList(language),
    staleTime: 24 * 60 * 60 * 1000,
    gcTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return query;
};
