import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { MapState } from './interfaces';
import { AutocompleteOption } from '../../models/AutocompleteOption';

const setSelectedLocation: CaseReducer<MapState, PayloadAction<AutocompleteOption | null>> = (
  state,
  action,
) => {
  state.selectedLocation = action.payload;
};

const setMyLocation: CaseReducer<MapState, PayloadAction<AutocompleteOption | null>> = (
  state,
  action,
) => {
  state.myLocation = action.payload;
};

const setIsDefaultView: CaseReducer<MapState, PayloadAction<boolean>> = (state, action) => {
  state.isDefaultView = action.payload;
};

const setMapBounds: CaseReducer<
  MapState,
  PayloadAction<{
    center: { lng: number; lat: number } | any;
    distance: number;
  }>
> = (state, action) => {
  const center = action.payload.center;

  state.distance = action.payload.distance * 1000; //distance in meters
  state.center = {
    lat: center.lat,
    lng: center.lng,
  };
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapBounds,
    setSelectedLocation,
    setIsDefaultView,
    setMyLocation,
  },
});

export const { name, actions } = mapSlice;

export default mapSlice.reducer;
