import React from 'react';
import { Page, View, Link, Text, Image, Svg, Polygon } from '@react-pdf/renderer';
import { Header } from '../Header/Header';
import { LiveCourses } from '../LiveCourses/LiveCourses';
import { OnlinePlus } from '../OnlinePlus/OnlinePlus';
import { BulletPoints } from '../BulletPoints/BulletPoints';
import { OnlineCourses } from '../OnlineCourses/OnlineCourses';
import { Order } from '../BulletPoints/intefaces';
import { Styles } from './AdditionalPages.styles';
import fitnessApp from '../../../../assets/icons/sp_app_fitnessraum.png';
import kernwerkApp from '../../../../assets/icons/sp_app_kernwerk.png';
import yogaApp from '../../../../assets/icons/sp_app_yoga.png';
import balloonApp from '../../../../assets/icons/sp_app_balloon.png';
import upfitApp from '../../../../assets/icons/sp_app_upfit.png';
import komootApp from '../../../../assets/icons/komoot_app.png';
import siebenSchlaeferApp from '../../../../assets/icons/sp_app_siebenschlaefer.png';
import fitbaseLogo from '../../../../assets/icons/fitbase_image.png';
import { PlusPoints } from '../PlusPoints/PlusPoints';
import { IntlShape } from 'react-intl';

export interface AdditionalPagesProps {
  intl: IntlShape;
}
export const AdditionalPages = ({ intl }: AdditionalPagesProps) => {
  return (
    <>
      <Page size='A4' wrap={false}>
        <Svg width='100%' height='820px' style={Styles.background}>
          <Polygon points='0,100 596,0 596,820 0,820' fill='#EFF2F6' />
        </Svg>
        <Header
          headerText={intl.formatMessage({
            id: 'export.pdf.online_offers.header',
            defaultMessage: 'Our online offer',
          })}
        />
        <LiveCourses
          infoHeader={intl.formatMessage({
            id: 'export.pdf.online_offers.online_live_courses.header',
            defaultMessage: 'Online-Live-Courses',
          })}
          infoText={intl.formatMessage({
            id: 'export.pdf.online_offers.online_live_courses.description',
            defaultMessage:
              'Access to live online courses from our alliance partners in the Hansefit app (more than 6,000 courses for all fitness levels, with different lengths and focuses)',
          })}
        />
        <View style={Styles.container}>
          <OnlinePlus
            headerText={intl.formatMessage({
              id: 'export.pdf.online_offers.online_plus.header',
              defaultMessage: 'Hansefit Online+',
            })}
            descriptionText={intl.formatMessage({
              id: 'export.pdf.online_offers.online_plus.description',
              defaultMessage:
                'Access to live online courses from our alliance partners in the Hansefit app (more than 6,000 courses for all fitness levels, with different lengths and focuses)',
            })}
          />
          <BulletPoints
            header={intl.formatMessage({
              id: 'export.pdf.fitness_app.bullet_point.header',
              defaultMessage: 'Fitness-App "FitnessRaum"',
            })}
            bulletPoints={[
              intl.formatMessage({
                id: 'export.pdf.fitness_app.bullet_point.1',
                defaultMessage: 'Unlimited use of over 2,000 courses',
              }),
              intl.formatMessage({
                id: 'export.pdf.fitness_app.bullet_point.2',
                defaultMessage: 'For beginners and advanced',
              }),
            ]}
            image={fitnessApp}
            order={Order.Default}
          />
          <BulletPoints
            header={intl.formatMessage({
              id: 'export.pdf.kernwerk_app.bullet_point.header',
              defaultMessage: 'Fitness-App "Kernwerk"',
            })}
            bulletPoints={[
              intl.formatMessage({
                id: 'export.pdf.kernwerk_app.bullet_point.1',
                defaultMessage: 'The first AI coach',
              }),
              intl.formatMessage({
                id: 'export.pdf.kernwerk_app.bullet_point.2',
                defaultMessage: 'For flexible training with body weight and equipment',
              }),
              intl.formatMessage({
                id: 'export.pdf.kernwerk_app.bullet_point.3',
                defaultMessage: 'For all fitness levels',
              }),
            ]}
            image={kernwerkApp}
            order={Order.Reverse}
          />
          <BulletPoints
            header={intl.formatMessage({
              id: 'export.pdf.yoga_easy_app.bullet_point.header',
              defaultMessage: 'App "Yogaeasy"',
            })}
            bulletPoints={[
              intl.formatMessage({
                id: 'export.pdf.yoga_easy_app.bullet_point.1',
                defaultMessage: 'Yoga and meditation with the most experienced teachers',
              }),
              intl.formatMessage({
                id: 'export.pdf.yoga_easy_app.bullet_point.2',
                defaultMessage: 'Over 2,000 videos, 70 programs and regular live classes',
              }),
              intl.formatMessage({
                id: 'export.pdf.yoga_easy_app.bullet_point.3',
                defaultMessage: 'All styles and levels',
              }),
            ]}
            image={yogaApp}
            order={Order.Default}
          />
          <BulletPoints
            header={intl.formatMessage({
              id: 'export.pdf.meditation_app.bullet_point.header',
              defaultMessage: 'Meditations-App „Balloon“',
            })}
            bulletPoints={[
              intl.formatMessage({
                id: 'export.pdf.meditation.bullet_point.1',
                defaultMessage:
                  'Helps to learn to cope with stress, sleep better and work more focused.',
              }),
            ]}
            image={balloonApp}
            order={Order.Reverse}
          />
          <Text style={Styles.textStyles}>
            {intl.formatMessage({
              id: 'export.pdf.more_information.text',
              defaultMessage: 'More information about the online offers is available',
            })}{' '}
            <Link style={Styles.linkStyles} src='https://hansefit.de/mitarbeiter/online-angebote/'>
              {intl.formatMessage({
                id: 'export.pdf.more_information.link',
                defaultMessage: 'here',
              })}
            </Link>
            .
          </Text>
        </View>
      </Page>
      <Page size='A4' wrap={false}>
        <Svg width='100%' height='520px' style={Styles.secondPageBackground}>
          <Polygon points='0,0 596,0 596,418 0,480' fill='#EFF2F6' />
        </Svg>
        <Header />
        <View style={Styles.secondContainer}>
          <BulletPoints
            header={intl.formatMessage({
              id: 'export.pdf.upfit_app.bullet_point.header',
              defaultMessage: 'App "Upfit"',
            })}
            bulletPoints={[
              intl.formatMessage({
                id: 'export.pdf.upfit_app.bullet_point.1',
                defaultMessage: 'The digital meal planner to lose weight without dieting',
              }),
            ]}
            image={upfitApp}
            order={Order.Default}
          />
          <BulletPoints
            header={intl.formatMessage({
              id: 'export.pdf.komoot_app.bullet_point.header',
              defaultMessage: 'APP „KOMOOT“',
            })}
            bulletPoints={[
              intl.formatMessage({
                id: 'export.pdf.komoot_app.bullet_point.1',
                defaultMessage:
                  'The outdoor app offers you numerous tours and supports you with route planning and navigation – whether you are travelling by bike or on foot.',
              }),
            ]}
            image={komootApp}
            order={Order.Reverse}
          />
          <BulletPoints
            header={intl.formatMessage({
              id: 'export.pdf.7_schlaefer_app.bullet_point.header',
              defaultMessage: 'App "7Schläfer"',
            })}
            bulletPoints={[
              intl.formatMessage({
                id: 'export.pdf.7_schlaefer_app.bullet_point.1',
                defaultMessage:
                  'You can take a closer look at your sleeping habits. You learn what constitutes good sleep and how you can ensure restful nights during the day.',
              }),
            ]}
            image={siebenSchlaeferApp}
            order={Order.Default}
          />

          <OnlineCourses
            header={intl.formatMessage({
              id: 'export.pdf.online_courses.header',
              defaultMessage: 'Online prevention courses',
            })}
            subHeader={intl.formatMessage({
              id: 'export.pdf.online_courses.sub_header',
              defaultMessage: 'according to §20 SGB V',
            })}
          />
          <PlusPoints
            header={intl.formatMessage({
              id: 'export.pdf.movement.header',
              defaultMessage: 'Movement',
            })}
            PlusPoints={[
              intl.formatMessage({
                id: 'export.pdf.movement.plus_point.1',
                defaultMessage: 'Spinal gymnastics: More flexible through everyday life',
              }),
              intl.formatMessage({
                id: 'export.pdf.movement.plus_point.2',
                defaultMessage: 'Back school: strengthen your back & prevent complaints',
              }),
              intl.formatMessage({
                id: 'export.pdf.movement.plus_point.3',
                defaultMessage: 'Fit & active: More active & healthier through whole-body training',
              }),
            ]}
          />
          <PlusPoints
            header={intl.formatMessage({
              id: 'export.pdf.nutrition.header',
              defaultMessage: 'Nutrition',
            })}
            PlusPoints={[
              intl.formatMessage({
                id: 'export.pdf.nutrition.plus_point.1',
                defaultMessage: 'Healthy weight loss:Healthy & successful to the feel-good weight',
              }),
              intl.formatMessage({
                id: 'export.pdf.nutrition.plus_point.2',
                defaultMessage: 'Healthy nutrition:More active & energetic in everyday life',
              }),
            ]}
          />
          <PlusPoints
            header={intl.formatMessage({
              id: 'export.pdf.relaxation.header',
              defaultMessage: 'Relaxation',
            })}
            PlusPoints={[
              intl.formatMessage({
                id: 'export.pdf.relaxation.plus_point.1',
                defaultMessage: 'Autogenic training: reduce stress & calm down',
              }),
              intl.formatMessage({
                id: 'export.pdf.relaxation.plus_point.2',
                defaultMessage: 'Hatha Yoga: relaxation for body and mind',
              }),
              intl.formatMessage({
                id: 'export.pdf.relaxation.plus_point.3',
                defaultMessage: 'Stress Management: Strengthen Resilience & Become More Relaxed',
              }),
              intl.formatMessage({
                id: 'export.pdf.relaxation.plus_point.4',
                defaultMessage: 'Sleep coaching: More relaxed through restful sleep',
              }),
              intl.formatMessage({
                id: 'export.pdf.relaxation.plus_point.5',
                defaultMessage: 'Mindfulness: Become more serene & content',
              }),
            ]}
          />
          <PlusPoints
            header={intl.formatMessage({
              id: 'export.pdf.addiction_prevention.header',
              defaultMessage: 'Addiction Prevention',
            })}
            PlusPoints={[
              intl.formatMessage({
                id: 'export.pdf.addiction_prevention.plus_point.1',
                defaultMessage: 'Non-smokers: Stop smoking now!',
              }),
            ]}
          />
          <Image src={fitbaseLogo} style={Styles.logo} />
        </View>
      </Page>
    </>
  );
};
