import { StyleSheet } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '100px',
    marginBottom: '11px',
  },

  header: {
    fontSize: '12px',
    fontFamily: 'Roboto-Bold',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#1f2126',
  },
  plusPointsContainer: {
    marginTop: '4px',
    display: 'flex',
    gap: '3px',
    flex: '1',
    width: '100%',
  },
  plusPointContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    width: '400px',
    alignItems: 'center',
  },
  plusPoint: {
    fontFamily: 'Roboto-Regular',
    fontSize: '10px',
    marginLeft: '3px',
    color: '#1f2126',
    wordWrap: 'break-word',
    flex: '1',
    maxWidth: '290px',
  },
  imageContainer: {
    width: '10px',
    height: '10px',
  },
});
