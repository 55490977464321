import { Header } from '../Header/Header';
import {
  ButtonContainer,
  ContactButton,
  Container,
  HeaderInfo,
  IconBox,
  InfoText,
  LinkInfoBox,
} from './NotFound.styles';
import errorIcon from '../../assets/icons/error-handling.svg';
import { useIntl } from 'react-intl';

const NotFound = () => {
  const intl = useIntl();
  return (
    <Container>
      <Header />
      <LinkInfoBox>
        <IconBox>
          <img src={errorIcon} alt={''} />
        </IconBox>
        <HeaderInfo>
          {intl.formatMessage({
            id: 'not_found.header',
            defaultMessage: 'Link no longer valid',
          })}
        </HeaderInfo>
        <InfoText>
          {' '}
          {intl.formatMessage({
            id: 'not_found.description',
            defaultMessage:
              'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos etaccusam et justo.',
          })}
        </InfoText>
        <ButtonContainer>
          <ContactButton href="mailto:info@hansefit.de" variant="body1">
            {intl.formatMessage({
              id: 'not_found.cta.contact',
              defaultMessage: 'Contact sales',
            })}
          </ContactButton>
        </ButtonContainer>
      </LinkInfoBox>
    </Container>
  );
};

export default NotFound;
