import { Text, View, Image } from '@react-pdf/renderer';
import { Styles } from './OnlineCourses.styles';
import cubeLogo from '../../../../assets/icons/cube_image@3x.png';

export interface OnlineCoursesProps {
  header: string;
  subHeader: string;
}

export const OnlineCourses = ({ header, subHeader }: OnlineCoursesProps) => {
  return (
    <View>
      <View style={Styles.headerSection}>
        <View>
          {header && <Text style={Styles.header}>{header}</Text>}
          {subHeader && <Text style={Styles.subHeader}>{subHeader}</Text>}
        </View>
        <Image src={cubeLogo} style={Styles.cubeLogo} />
      </View>
    </View>
  );
};
