import { Text, View, Svg, Path, Circle, G } from '@react-pdf/renderer';
import { Styles } from './PlusPoints.styles';

export interface PlusPointsProps {
  header: string;
  PlusPoints: string[];
}

export const PlusPoints = ({ header, PlusPoints }: PlusPointsProps) => {
  return (
    <View style={Styles.container}>
      <View>
        <Text style={Styles.header}>{header}</Text>
        <View style={Styles.plusPointsContainer}>
          {PlusPoints.map((PlusPoint, idx) => {
            return (
              <View key={idx} style={Styles.plusPointContainer}>
                <View style={Styles.imageContainer}>
                  <Svg width="14" height="14" viewBox="0 0 10 10">
                    <G fill="none" fillRule="evenodd">
                      <Circle fill="#5C92FF" cx="5" cy="5" r="5" />
                      <Path
                        d="M5 2.5c.16 0 .293.12.31.276l.003.037v1.874h1.875a.313.313 0 0 1 .036.623l-.037.003H5.313v1.875a.313.313 0 0 1-.622.036l-.003-.037V5.313H2.813a.313.313 0 0 1-.036-.622l.037-.003h1.874V2.812c0-.172.14-.312.313-.312"
                        fill="#FFF"
                      />
                    </G>
                  </Svg>
                </View>

                <Text style={Styles.plusPoint}>{PlusPoint}</Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};
