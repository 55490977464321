import { StyleSheet } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '74px',
  },
  reverseContainer: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    paddingLeft: '68px',
  },
  reverseSecondaryColumn: {
    width: '339px',
  },
  header: {
    marginTop: '42px',
    fontSize: '12px',
    fontFamily: 'Roboto-Bold',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#1f2126',
  },
  bulletPointsContainer: {
    marginTop: '6px',
    display: 'flex',
    gap: '5px',
    flex: '1',
    width: '100%',
  },
  bulletPointContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3px',
  },
  bulletPoint: {
    fontFamily: 'Roboto-Regular',
    fontSize: '10px',
    color: '#1f2126',
    marginLeft: '4px',
    wordWrap: 'break-word',
    flex: '1',
    maxWidth: '270px',
    lineHeight: '1.4',
    letterSpacing: '0.1px',
  },
  bulletPointIcon: {
    width: '12px',
    height: '12px',
  },
  logo: {
    width: '130px',
    marginRight: '33px',
  },

});
