import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Styles } from './Filter.styles';
import { IntlShape } from 'react-intl';

export interface FilterProps {
  intl: IntlShape;
  radius: number;
  categories: string;
  search: string;
  filteredMemberships: string;
  filteredCheckIns: string;
  filename: string | undefined;
}

export const Filter = ({
  intl,
  radius,
  categories,
  search,
  filteredMemberships,
  filteredCheckIns,
  filename,
}: FilterProps) => {
  return (
    <View style={Styles.container}>
      <Text style={Styles.header}>
        {intl.formatMessage({
          id: 'export.pdf.filter.header',
          defaultMessage: 'Your search criteria',
        })}
        :
      </Text>
      <View style={Styles.rowsContainer}>
        <View style={Styles.rowContainer}>
          <Text style={Styles.firstCol}>
            {intl.formatMessage({
              id: 'export.pdf.filter.radius',
              defaultMessage: 'Radius',
            })}
            :
          </Text>
          <Text style={Styles.secondCol}>{radius} km</Text>
        </View>
        <View style={Styles.rowContainer}>
          <Text style={Styles.firstCol}>
            {intl.formatMessage({
              id: 'export.pdf.filter.upload_file',
              defaultMessage: 'Upload file',
            })}
            :
          </Text>
          <Text style={Styles.secondCol}>{filename ? filename : '-'}</Text>
        </View>
        <View style={Styles.rowContainer}>
          <Text style={Styles.firstCol}>
            {intl.formatMessage({
              id: 'export.pdf.filter.location_search',
              defaultMessage: 'Location Search',
            })}
            :
          </Text>
          <Text style={Styles.secondCol}>{search}</Text>
        </View>
        <View style={Styles.rowContainer}>
          <Text style={Styles.firstCol}>
            {intl.formatMessage({
              id: 'export.pdf.filter.partner_categories',
              defaultMessage: 'Partner categories',
            })}
            :
          </Text>
          <Text style={Styles.secondCol}>{categories ? categories : '-'}</Text>
        </View>
        <View style={Styles.rowContainer}>
          <Text style={Styles.firstCol}>
            {intl.formatMessage({
              id: 'export.pdf.filter.membership',
              defaultMessage: 'Membership',
            })}
            :
          </Text>
          <Text style={Styles.secondCol}>{filteredMemberships ? filteredMemberships : '-'}</Text>
        </View>
        <View style={Styles.rowContainer}>
          <Text style={Styles.firstCol}>
            {intl.formatMessage({
              id: 'export.pdf.filter.check_in_services',
              defaultMessage: 'Check-in/Services',
            })}
            :
          </Text>
          <Text style={Styles.secondCol}>{filteredCheckIns ? filteredCheckIns : '-'}</Text>
        </View>
      </View>
    </View>
  );
};
