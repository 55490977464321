export interface AutocompleteOption {
  name: string;
  displayName?: string;
  lat: number;
  lng: number;
  type: AutocompleteOptionType;
  publicId?: string;
  placeId?: string;
  fileId?: string;
  zipCodes?: string[];
  filename?: string;
  predictionTypes?: string[];
}

export enum AutocompleteOptionType {
  City,
  Street,
  Studio,
  CurrentLocation,
  Prediction,
  File,
  ZipCode,
}
