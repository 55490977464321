import { Button, FormControl, IconButton, Select, styled, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { withTransientProps } from '../../data/theme/withTransientProps';

export const basicItemBackground = '#7DA8FE';
export const proItemBackground = '#3363C3';
export const bestItemBackground = '#334671';

export const Container = styled('div')`
  padding: 35px 62px 40px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props => props.theme.breakpoints.down('lg')} {
    padding: 26px 15px 20px;
    width: auto;
  }
`;

export const StyledFormControl = styled(FormControl)`
  & .MuiInputLabel-root {
    font-family: Roboto-Regular;
    font-size: 12px;

    &:not(.MuiInputLabel-shrink) {
      top: 2px;
    }
  }

  & .MuiInputLabel-root.Mui-focused,
  & .MuiInputLabel-shrink {
    font-family: Roboto-Regular;
    font-size: 1rem;
    line-height: 1.4375em;
    color: #003bb3;
  }

  & .MuiInputBase-root {
    & .MuiSelect-select {
      width: 254px;
      min-height: 1.4375em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      color: #1f2126;
      font-family: Roboto-Medium;

      & img {
        display: none;
      }
    }

    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border-color: #003bb3;
      }
    }
  }
`;

export const FilterTitle = styled(Typography)`
  font-family: NutmegHeadline-Ultra;
  font-size: 20px;
  color: #1f2126;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.down('lg')} {
    font-size: 20px;
  }
`;

export const SelectFilterTitle = styled(Typography)`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #1f2126;
  padding-left: 24px;
  width: 240px;

  ${props => props.theme.breakpoints.down('lg')} {
    width: 90%;
  }
`;
export const CloseButton = styled(IconButton)`
  align-self: flex-start;
  position: absolute;
  top: 12px;
  right: 12px;

  ${props => props.theme.breakpoints.down('lg')} {
    top: 18px;
    right: 8px;
  }
`;

export const Modal = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  top: 174px;
  left: 542px;
  width: 400px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;

  ${props => props.theme.breakpoints.down('lg')} {
    top: 0;
    left: 0;
    width: 100%;
  }
`;

export const InputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
`;

export const listStyles = makeStyles(theme => ({
  paper: {},
  root: {
    '& ul': {
      maxHeight: '400px',
      height: 'auto',
      display: 'inline-block',
      overflowY: 'scroll',
      paddingTop: '26px',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
      width: '300px',
    },
  },
  listCategory: {
    '& li': {
      fontSize: '12px',
      fontFamily: 'Roboto-Regular',
      paddingTop: '14px',
      paddingBottom: '7px',
      paddingLeft: '24px',
      paddingRight: '0',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'flex',
    },
    '& li.Mui-selected': {
      background: 'none',
      fontWeight: 'bold',
    },
    '& li:hover': {
      background: 'rgba(92, 146, 255, 0.1)',
    },
  },
  rootMembership: {
    '& ul': {
      width: '280px',
      maxHeight: '400px',
      height: 'auto',
      display: 'inline-block',
      overflowY: 'scroll',
      paddingTop: '26px',
      padding: 0,
    },
  },
  listMembership: {
    '& li': {
      fontSize: '12px',
      fontFamily: 'Roboto-Regular',
      paddingTop: '14px',
      paddingBottom: '7px',
      paddingLeft: '0',
      paddingRight: '0',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-flex',
      width: 'auto',
      marginLeft: '24px',
    },
    '& li:nth-child(3), & li:nth-child(4)': {
      paddingLeft: '12px',
      marginLeft: 0,
    },
    '& li.Mui-selected:nth-child(2), li.Mui-selected:nth-child(3), li.Mui-selected:nth-child(4)': {
      background: 'none',
    },
    '& li.Mui-selected:nth-child(2) div': {
      background: basicItemBackground,
      color: '#fff',
    },
    '& li.Mui-selected:nth-child(3) div': {
      background: proItemBackground,
      color: '#fff',
    },
    '& li.Mui-selected:nth-child(4) div': {
      background: bestItemBackground,
      color: '#fff',
    },
    '& li:hover': {
      background: 'rgba(92, 146, 255, 0.1)',
    },
  },
  rootCheckIn: {
    '& ul': {
      maxHeight: '400px',
      height: 'auto',
      display: 'inline-block',
      overflowY: 'scroll',
      paddingTop: '26px',
      paddingBottom: '22px',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '300px',
      },
    },
  },
  listCheckIn: {
    '& li': {
      fontSize: '12px',
      fontFamily: 'Roboto-Regular',
      paddingTop: '14px',
      paddingBottom: '7px',
      paddingLeft: '24px',
      paddingRight: '0',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-flex',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '240px',
      },
    },
    '& li.Mui-selected': {
      background: 'none',
      fontFamily: 'Roboto-Medium',
    },
    '& li.Mui-selected img': {
      filter:
        'invert(21%) sepia(89%) saturate(7081%) hue-rotate(331deg) brightness(86%) contrast(111%)',
    },
  },
}));

export const CategoryIcon = styled('img')`
  width: auto;
  height: 18px;
  object-fit: cover;
  margin-right: 10px;
`;

export const CheckInIcon = styled('img')`
  width: 22px;
  height: auto;
  object-fit: cover;
  margin-right: 10px;
`;

export const MembershipIcon = styled('img')`
  width: auto;
  height: 22px;
  object-fit: cover;
  margin-right: 4px;
`;

export const MembershipContainer = styled('div')`
  border: 1px solid #d8d8d8;
  border-radius: 32px;
  text-transform: capitalize;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  padding-right: 16px;
`;

export const MobileMembershipContainer = styled(MembershipContainer, withTransientProps)<{
  isSelected: boolean;
  backgroundColor: string;
}>`
  background-color: ${props => (props.isSelected ? props.backgroundColor : 'none')};
  color: ${props => (props.isSelected ? '#fff' : '#1f2126')};
`;

export const CtaContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;

  ${props => props.theme.breakpoints.down('lg')} {
    flex-direction: column-reverse;
    margin-top: 15px;
  }
`;

export const ResetButton = styled(Button)`
  color: #003bb3;
  font-family: Roboto-Medium;
  font-size: 14px;
  border-color: #003bb3;
  border-radius: 16px;
  ${props => props.theme.breakpoints.down('lg')} {
    color: #6b7986;
    width: calc(100vw - 30px);
    height: 30px;
    opacity: 0.5;
    border-radius: 24px;
    border: solid 1px #6b7986;
  }
`;

export const ActiveResetButton = styled(ResetButton)`
  color: #003bb3 !important;
  border: solid 1px #003bb3 !important;
  opacity: 1 !important;
`;

export const CtaPlaceholder = styled('div')`
  flex: 1;
`;

export const FilterButton = styled(Button)`
  color: #fff;
  font-family: Roboto-Medium;
  font-size: 14px;
  border-radius: 24px;
  background-color: #003bb3;
  box-shadow: none;
  padding: 6px 26px;

  ${props => props.theme.breakpoints.down('lg')} {
    width: calc(100vw - 30px);
    height: 30px;
    margin: 10px 0;
    opacity: 0.5;
    background-color: #6b7986;
  }
`;

export const ActiveFilterButton = styled(FilterButton)`
  background-color: #003bb3 !important;
  opacity: 1 !important;
`;

export const MembershipMobileItemsTitle = styled(Typography)`
  margin: 10px 0 0;
  font-size: 12px;
  font-family: Roboto-Regular;
  color: #1f2126;
`;

export const MembershipMobileItems = styled('ul')`
  display: flex;
  padding: 0;
  margin: 0;

  & li {
    font-size: 12px;
    font-family: Roboto-Regular;
    margin: 10px 0 0;
    padding: 0 4px 0 4px;
    position: relative;
    right: 4px;
  }

,
`;

export const Divider = styled('div')`
  height: 2px;
  background-color: #eff2f6;
  margin-left: 24px;
  margin-top: 14px;
  margin-bottom: 8px;
`;

export const StyledMembershipSelect = styled(Select)`
  & .MuiSelect-select {
    text-transform: capitalize;
  }
`;

export const CheckInInfoButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  bottom: 20px;

  ${props => props.theme.breakpoints.down('lg')} {
    bottom: 22px;
  }
`;

export const MembershipInfoButton = styled(IconButton)`
  width: 100%;
  height: auto;
  font-family: Roboto;
  font-size: 12px;
  color: #003bb3;
  background-color: white;
  justify-content: flex-start;
  display: flex;
  gap: 8px;
  padding-left: 0;
  &:hover {
    background: none;
  }
`;

export const MembershipInfoContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 18px;
  margin-left: 25px;

  ${props => props.theme.breakpoints.down('lg')} {
    margin-left: 4px;
    padding-top: 4px;
    padding-bottom: 0;
  }
`;

export const StyledDropdownIcon = styled('img')`
  &.MuiSelect-icon.MuiSelect-iconOutlined {
    position: absolute;
    top: 8px;
    right: 6px;
  }
`;
