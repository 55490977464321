import { Button, Link, styled, Typography } from '@mui/material';
import { withTransientProps } from '../../data/theme/withTransientProps';

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const LeftColumn = styled('div')`
  width: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
`;

export const RightColumn = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

export const CategoryImage = styled('img')`
  object-fit: cover;
  width: 48px;
  height: 48px;
`;

export const Tags = styled('div')`
  display: flex;
  flex-direction: row;
  font-family: Roboto-Medium;
  font-size: 12px;
  margin-right: 8px;
  margin-top: 13px;
  flex: 1;
`;

export const Details = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const Container = styled('div')`
  flex: 1;
  height: 100%;
`;

export const ColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const StudioContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 22px 24px 17px 30px;
  background-color: #fff;

  &:hover {
    background-color: #eff5ff;
  }
`;

export const Divider = styled('div')`
  height: 1px;
  background-color: #d8d8d8;
  width: 100%;
`;

export const DetailsButton = styled(Button)`
  background-color: #1e48ae;
  border-radius: 32px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  min-width: fit-content;
  box-shadow: none;
  opacity: 1;
  font-size: 12px;
  &:hover {
    background-color: #002a80;
    box-shadow: none;
  }
`;

export const StudioName = styled(Link)`
  font-family: Roboto-Medium;
  font-size: 18px;
  color: #1f2126;
  text-decoration: none;
  margin-bottom: 9px;
`;

export const Address = styled(Typography)`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #1f2126;
`;

export const MarkerImage = styled('img')`
  width: 10px;
  margin-right: 3px;
  position: relative;
  top: 2px;
`;

export const DistanceContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: Roboto-Regular;
  font-size: 10px;
  margin-right: 10px;
`;

export const RelativeContainer = styled('div')`
  position: relative;
`;

export const LocationButtonContainer = styled('div', withTransientProps)<{ $showList: boolean }>`
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #1f2126;
  width: max-content;
  cursor: pointer;
  gap: 9px;

  margin-bottom: ${props => (props.$showList ? '24px' : '0px')};

  margin-top: 12px;

  ${props => props.theme.breakpoints.down('lg')} {
    margin-top: 0px;
  }
`;
