import { StyleSheet } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
  header: {
    fontSize: '16px',
    fontFamily: 'NutmegHeadline-Ultra',
    textTransform: 'uppercase',
    color: '#00194d',
  },
  subHeader: {
    fontSize: '12px',
    fontFamily: 'Roboto-Regular',
    color: '#1f2126',
    marginTop: '3px',
  },

  headerSection: {
    display: 'flex',
    marginTop: '70px',
    paddingLeft: '100px',
    marginBottom: '12px',
  },
  cubeLogo: {
    width: '72px',
    position: 'absolute',
    left: '0',
    zIndex: '1',
    marginTop: '16px',
    top: '-56px',
    height: '392px'
  },
});
