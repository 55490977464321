import { StudioSortType, StudioState } from './interfaces';

export const initialState: StudioState = {
  visibleStudios: [],
  inputInformation: [],
  studioGroups: [],
  categories: [],
  equipmentAndServices: [],
  query: '',

  radiusFilterValue: 10,
  hover: null,
  selected: null,
  selectedStudios: [],
  highlightedStudios: [],
  selectedStudioModalOpen: true,
  filter: {
    categories: [],
    servicePackages: [],
    checkInCard: false,
    checkInApp: false,
    hansefitCard: false,
    equipmentAndServices: [],
  },
  sort: StudioSortType.Distance,
};
