import React from 'react';
import LogoImage from '../../assets/icons/hansefit_logo_epassi_web_transparent.svg';
import { Container, LinkContainer, Logo, PageLink, Separator, TopBar } from './Header.styles';
import { useIntl } from 'react-intl';

export const Header = () => {
  const intl = useIntl();
  return (
    <Container>
      <Logo src={LogoImage} />
      <TopBar>
        <LinkContainer>
          <PageLink href='https://hansefit.de' target='_blank' rel='noopener noreferrer'>
            {intl.formatMessage({
              id: 'header.href.hansefit',
              defaultMessage: 'hansefit.de',
            })}
          </PageLink>
          <Separator>|</Separator>
          <PageLink href='https://hansefit.de/impressum' target='_blank'>
            {intl.formatMessage({
              id: 'header.href.imprint',
              defaultMessage: 'Imprint',
            })}
          </PageLink>
          <Separator>|</Separator>
          <PageLink href='https://hansefit.de/datenschutz' target='_blank'>
            {intl.formatMessage({
              id: 'header.href.privacy',
              defaultMessage: 'Privacy',
            })}
          </PageLink>
        </LinkContainer>
      </TopBar>
    </Container>
  );
};
