import {
  ResultsContainer,
  HeaderContainer,
  FirstColumnHeader,
  SecondColumnHeader,
  ThirdColumnHeader,
  GroupRow,
  DataContainer,
  StyledToggleImage,
  FirstColumnContainer,
  SecondColumnContainer,
  ThirdColumnContainer,
  QuickInformation,
  StudioListContainer,
  Divider,
  List,
  ListItem,
  CategoryIcon,
  FirstColumnStudioListItem,
  StudioName,
  SecondColumnStudioListItem,
  StyledToggleButton,
  InformationName,
  Placeholder,
} from './StudioSearchResultsTable.styles';
import tableOpenIcon from '../../assets/icons/atoms-cta-table-open.svg';
import tableCloseIcon from '../../assets/icons/atoms-cta-table-close.svg';
import React, { useMemo, useState } from 'react';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { TableData } from './interfaces';
import { Typography } from '@mui/material';
import {
  getCategoryById,
  getDistanceInKilometers,
  getPartnerPath,
} from '../../store/studio/studio.utils';
import { StudioModel } from '../../models/StudioModel';
import { StudioActions } from '../../store/studio';
import { useIntl } from 'react-intl';
import sortBy from 'lodash.sortby';
import { StudioSortType } from '../../store/studio/interfaces';
import { getLanguage } from '../../utils/language.utils';

export const StudioSearchResultsTable = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [openZipCode, setOpenZipCode] = useState('');
  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const studioGroups = useSelector(StudioSelectors.getStudioGroups);
  const allCategories = useSelector(StudioSelectors.getCategories);
  const sort = useSelector(StudioSelectors.getSort);
  const language = getLanguage();

  const tableData: TableData[] = useMemo(
    () =>
      studioGroups.map(studioGroup => {
        const foundInformation = inputInformation.find(
          information => information.id === studioGroup.inputInformationId,
        );

        if (foundInformation) {
          const nearestStudioDistance =
            studioGroup.visibleStudios.length > 0
              ? Math.min(
                  ...studioGroup.visibleStudios.map(studio =>
                    getDistanceInKilometers(
                      { lat: studio.coordLat, lng: studio.coordLong },
                      { lat: foundInformation.lat, lng: foundInformation.lng },
                    ),
                  ),
                )
              : 0;

          // Sort by distance
          // Keep it for reference
          // const sortedVisibleStudios = studioGroup.visibleStudios
          //   .map(studio => ({
          //     ...studio,
          //     distanceFromPosition: getDistanceInKilometers(
          //       { lat: studio.coordLat, lng: studio.coordLong },
          //       { lat: foundInformation.lat, lng: foundInformation.lng },
          //     ),
          //   }))
          //   .sort((a, b) => {
          //     if (b.distanceFromPosition > a.distanceFromPosition) {
          //       return -1;
          //     }

          //     if (b.distanceFromPosition > a.distanceFromPosition) {
          //       return 1;
          //     }

          //     return 0;
          //   });
          // return {
          //   nearestStudioDistance,
          //   inputInformation: foundInformation,
          //   studioGroup: { ...studioGroup, visibleStudios: sortedVisibleStudios },
          // };

          const extendedStudioData = studioGroup.visibleStudios.map(studio => ({
            ...studio,
            studioName: studio.studioName.trim(),
            distanceFromPosition: getDistanceInKilometers(
              { lat: studio.coordLat, lng: studio.coordLong },
              { lat: foundInformation.lat, lng: foundInformation.lng },
            ),
          }));

          return {
            nearestStudioDistance,
            inputInformation: foundInformation,
            studioGroup: {
              ...studioGroup,
              visibleStudios: sortBy(extendedStudioData, ['studioName'], ['asc']),
            },
          };
        } else {
          return { nearestStudioDistance: 0, studioGroup };
        }
      }),
    [inputInformation, studioGroups],
  );

  const sortedData = useMemo(
    () =>
      tableData.map(studioGroup => {
        const sortedVisibleStudios =
          sort === StudioSortType.Distance
            ? sortBy(studioGroup.studioGroup.visibleStudios, ['distanceFromPosition'], ['asc'])
            : sortBy(studioGroup.studioGroup.visibleStudios, ['studioName'], ['asc']);
        return {
          ...studioGroup,
          studioGroup: {
            ...studioGroup.studioGroup,
            visibleStudios: sortedVisibleStudios,
          },
        };
      }),
    [tableData, sort],
  );

  const toggle = (id: string) => {
    if (openZipCode === id) {
      setOpenZipCode('');
    } else {
      setOpenZipCode(id);
    }
  };

  const handleSelectStudios = (studios: StudioModel[]) => {
    const studioIds = studios.map(studio => studio.publicId);

    dispatch(StudioActions.selectStudios(studioIds));
  };

  const handleDeselectStudios = () => {
    dispatch(StudioActions.selectStudios([]));
  };

  return (
    <ResultsContainer>
      <HeaderContainer>
        <FirstColumnHeader>
          {intl.formatMessage({
            id: 'search_results_table.first_column_header',
            defaultMessage: 'Address, city or postal code',
          })}
        </FirstColumnHeader>
        <SecondColumnHeader>
          {intl.formatMessage({
            id: 'search_results_table.second_column_header',
            defaultMessage: 'Quantity/Distance',
          })}
        </SecondColumnHeader>
        <ThirdColumnHeader>
          {intl.formatMessage({
            id: 'search_results_table.third_column_header',
            defaultMessage: 'next partner',
          })}
        </ThirdColumnHeader>
      </HeaderContainer>
      <DataContainer>
        {sortedData.map(item => (
          <GroupRow key={item.studioGroup.inputInformationId}>
            <QuickInformation>
              <FirstColumnContainer>
                {item.studioGroup.visibleStudios.length > 0 ? (
                  <StyledToggleButton onClick={() => toggle(item.studioGroup.inputInformationId)}>
                    <StyledToggleImage
                      alt=''
                      src={
                        item.studioGroup.inputInformationId === openZipCode
                          ? tableOpenIcon
                          : tableCloseIcon
                      }
                    />
                  </StyledToggleButton>
                ) : (
                  <Placeholder />
                )}
                <InformationName> {item.inputInformation?.name}</InformationName>
              </FirstColumnContainer>

              <SecondColumnContainer
                onMouseOver={() => handleSelectStudios(item.studioGroup.visibleStudios)}
                onMouseOut={handleDeselectStudios}
                onClick={() => toggle(item.studioGroup.inputInformationId)}>
                <Typography>
                  {item.studioGroup.visibleStudios.length > 0
                    ? `${item.studioGroup.visibleStudios.length} VP`
                    : '-'}
                </Typography>
              </SecondColumnContainer>

              <ThirdColumnContainer>
                {item.studioGroup.visibleStudios.length > 0 && (
                  <Typography>{item.nearestStudioDistance} km</Typography>
                )}
                {item.studioGroup.visibleStudios.length === 0 && <Typography>-</Typography>}
              </ThirdColumnContainer>
            </QuickInformation>
            {openZipCode === item.studioGroup.inputInformationId && (
              <StudioListContainer>
                <Divider />
                <List>
                  {item.studioGroup.visibleStudios.map(studio => {
                    const category = getCategoryById(allCategories, studio.categoryPrimary);

                    const path = getPartnerPath(studio, language, allCategories);

                    return (
                      <ListItem key={studio.publicId}>
                        <FirstColumnStudioListItem>
                          {category.iconUrlCircle && <CategoryIcon src={category.iconUrlCircle} />}
                          <StudioName
                            href={`${process.env.REACT_APP_HANSEFIT_WEBSITE}${path}`}
                            target='_blank'>
                            {studio.studioName}
                          </StudioName>
                        </FirstColumnStudioListItem>
                        <SecondColumnStudioListItem>
                          {studio.distanceFromPosition} km
                        </SecondColumnStudioListItem>
                      </ListItem>
                    );
                  })}
                </List>
              </StudioListContainer>
            )}
          </GroupRow>
        ))}
      </DataContainer>
    </ResultsContainer>
  );
};
