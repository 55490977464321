import { RootState } from '../store';

const getSelectedLocation = (state: RootState) => state.map.selectedLocation;

const getCenter = (state: RootState) => state.map.center;

const getDistance = (state: RootState) => state.map.distance;

const getIsDefaultView = (state: RootState) => state.map.isDefaultView;

const getMyLocation = (state: RootState) => state.map.myLocation;

export const MapSelectors = {
  getSelectedLocation,
  getIsDefaultView,
  getCenter,
  getDistance,
  getMyLocation,
};
