import { CircularProgress } from '@mui/material';
import { LoaderContainer } from './SpinnerLoader.styles';

export const SpinnerLoader = () => {
  return (
    <LoaderContainer sx={{ margin: '0 auto', padding: '36px' }}>
      <CircularProgress variant='indeterminate' disableShrink thickness={6} />
    </LoaderContainer>
  );
};
