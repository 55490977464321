import { useDispatch, useSelector } from 'react-redux';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import {
  StudioModalContainer,
  Row,
  LeftColumn,
  RightColumn,
  CategoryImage,
  DetailsButton,
  StudioName,
  Address,
  CloseButton,
} from './SelectedStudioModal.styles';
import {
  getCategoryById,
  getDistanceInKilometers,
  getPartnerPath,
} from '../../store/studio/studio.utils';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  DistanceContainer,
  MarkerImage,
} from '../StudioSearchResults/StudioSearchresultslist.styles';
import searchMarkerIcon from '../../assets/icons/search-marker.svg';
import { MapSelectors } from '../../store/map/map.selectors';
import filtersModalIcon from '../../assets/icons/close.svg';
import { StudioActions } from '../../store/studio';
import { Box } from '@mui/material';
import { getLanguage } from '../../utils/language.utils';

const SelectedStudioModal = () => {
  const intl = useIntl();
  const selectedStudio = useSelector(StudioSelectors.getSelected);
  const allCategories = useSelector(StudioSelectors.getCategories);
  const selectedLocation = useSelector(MapSelectors.getSelectedLocation);
  const accuracy = 'km';
  const dispatch = useDispatch();
  const language = getLanguage();

  const handleClose = () => {
    dispatch(StudioActions.setSelectedStudioModalOpen(false));
    dispatch(StudioActions.unselectStudio());
  };

  const path = useMemo(() => {
    if (!selectedStudio) {
      return null;
    }

    return getPartnerPath(selectedStudio, language, allCategories);
  }, [selectedStudio, language, allCategories]);

  if (null !== selectedStudio) {
    const category = getCategoryById(allCategories, selectedStudio.categoryPrimary);
    const distanceFromUserLocation =
      null !== selectedLocation
        ? getDistanceInKilometers(
            { lat: selectedStudio.coordLat, lng: selectedStudio.coordLong },
            { lat: selectedLocation.lat, lng: selectedLocation.lng },
          )
        : null;

    return (
      <StudioModalContainer>
        <Row>
          <LeftColumn>
            <CategoryImage
              src={
                category.iconUrlCircle
                  ? category.iconUrlCircle
                  : process.env.REACT_APP_ICON_URL_CIRCLE_FALLBACK
              }
              alt='Studio category image'
            />
          </LeftColumn>
          <RightColumn>
            <StudioName>{selectedStudio.studioName}</StudioName>
            <Address>
              {distanceFromUserLocation ? (
                <DistanceContainer>
                  <MarkerImage alt='' src={searchMarkerIcon} /> {distanceFromUserLocation}{' '}
                  <Box>{accuracy}</Box>
                </DistanceContainer>
              ) : (
                <></>
              )}
              <Box>
                {`${selectedStudio.street} ${selectedStudio.streetNumber}`}
                <br />
                {`${selectedStudio.zip} ${selectedStudio.city}`}
              </Box>
            </Address>
          </RightColumn>
          <CloseButton onClick={handleClose}>
            <img src={filtersModalIcon} alt='' />
          </CloseButton>
        </Row>
        <Row>
          <DetailsButton
            color='primary'
            variant='button'
            href={`${process.env.REACT_APP_HANSEFIT_WEBSITE}${path}`}
            rel='noopener noreferrer'>
            {intl.formatMessage({
              id: 'mobile_details_btn',
              defaultMessage: 'Show more details',
            })}
          </DetailsButton>
        </Row>
      </StudioModalContainer>
    );
  } else {
    return <></>;
  }
};

export default SelectedStudioModal;
