import { Box, Link, styled, Typography } from '@mui/material';

export const LinkInfoBox = styled('div')`
  width: 600px;
  background-color: #fff;
`;

export const HeaderInfo = styled(Typography)`
  font-size: 24px;
  font-family: NutmegHeadline-Ultra;
  color: #00194d;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  text-transform: uppercase;
`;
export const InfoText = styled(Typography)`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #1f2126;
  text-align: center;
  padding-left: 102px;
  padding-right: 103px;
  padding-top: 10px;
`;

export const Container = styled(Box)`
  background-image: linear-gradient(115deg, #002a80 14%, #d3e6f5 91%);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContactButton = styled(Link)`
  background-color: #013bb3;
  border-radius: 18px;
  font-family: Roboto-Medium;
  font-size: 14px;
  line-height: 1;
  padding: 12px 17px 10px 17px;
  :hover {
    cursor: pointer;
  }
  color: white;
  text-decoration: none;
  text-transform: uppercase;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 52px;
`;

export const IconBox = styled('div')`
  padding-top: 51px;
  display: flex;
  justify-content: center;
`;
