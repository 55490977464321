import bulletPointIcon from '../../../../assets/icons/checked.png';
import { Image, Text, View } from '@react-pdf/renderer';
import { Styles } from './BulletPoints.styles';
import { Order } from './intefaces';

export interface BulletPointsProps {
  header: string;
  bulletPoints: string[];
  image: string;
  order: Order;
}
export const BulletPoints = ({ header, bulletPoints, image, order }: BulletPointsProps) => {
  return (
    <View
      style={{ ...Styles.container, ...(order === Order.Reverse ? Styles.reverseContainer : {}) }}>
      <Image src={image} style={Styles.logo} />
      <View style={{ ...(order === Order.Reverse ? Styles.reverseSecondaryColumn : {}) }}>
        <Text style={Styles.header}>{header}</Text>
        <View style={Styles.bulletPointsContainer}>
          {bulletPoints.map((bulletPoint, idx) => {
            return (
              <View key={idx} style={Styles.bulletPointContainer}>
                <Image src={bulletPointIcon} style={Styles.bulletPointIcon} />
                <Text style={Styles.bulletPoint} break>
                  {bulletPoint}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};
