import { InputAdornment, Modal } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import copyIcon from '../../assets/icons/copy.svg';
import {
  ButtonContainer,
  CloseButton,
  CopyIcon,
  CopyLinkButton,
  InfoContainer,
  InfoText,
  ModalContainer,
  StyledCopyButton,
  StyledDescription,
  StyledHeader,
  StyledTextField,
} from './ShareModal.styles';
import closeIcon from '../../assets/icons/atoms-cta-close-blue.svg';
import { useMemo, useState } from 'react';
import { useUpdateShareResultsMutation } from '../../store/api/shareResults/shareResultsApi';
import { useSelector } from 'react-redux';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { useIntl } from 'react-intl';

export interface ShareModalProps {
  handleClose: () => void;
}

export const ShareModal = ({ handleClose }: ShareModalProps) => {
  const intl = useIntl();
  const [id] = useState(uuidv4());
  const [link] = useState(`${process.env.REACT_APP_VPE_CLIENT_DOMAIN}share/${id}`);
  const filter = useSelector(StudioSelectors.getFilter);
  const radiusFilterValue = useSelector(StudioSelectors.getRadiusFilterValue);
  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const studioGroups = useSelector(StudioSelectors.getStudioGroups);
  const emptyStudioGroups = useMemo(
    () =>
      studioGroups.map(studioGroup => ({
        ...studioGroup,
        visibleStudios: [],
      })),
    [studioGroups],
  );
  const [updateShareResultMutation] = useUpdateShareResultsMutation();

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(link);

    updateShareResultMutation({
      id,
      data: {
        studios: {
          inputInformation,
          studioGroups: emptyStudioGroups,
          filter,
          radiusFilterValue,
        },
      },
    });
  };

  return (
    <Modal open={true}>
      <ModalContainer>
        <CloseButton onClick={handleClose}>
          <img src={closeIcon} alt='' />
        </CloseButton>
        <StyledHeader>
          {intl.formatMessage({
            id: 'share_modal.header',
            defaultMessage: 'Share result',
          })}
        </StyledHeader>
        <StyledDescription>
          {intl.formatMessage({
            id: 'share_modal.description',
            defaultMessage:
              'Copy the link to the clipboard and send it by mail to share the search result. share.',
          })}
        </StyledDescription>
        <StyledTextField
          variant='outlined'
          size='small'
          disabled
          fullWidth
          value={link}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <StyledCopyButton onClick={handleCopyLink}>
                  <CopyIcon src={copyIcon} alt='' />
                </StyledCopyButton>
              </InputAdornment>
            ),
          }}
        />
        <ButtonContainer>
          <CopyLinkButton variant='contained' onClick={handleCopyLink}>
            {intl.formatMessage({
              id: 'share_modal.cta.copy_link',
              defaultMessage: 'Copy link',
            })}
          </CopyLinkButton>
          <InfoContainer>
            <InfoText>
              {intl.formatMessage({
                id: 'share_modal.info_text',
                defaultMessage: 'Please note that the link is only valid for 30 days.',
              })}
            </InfoText>
          </InfoContainer>
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};
