import { Box, styled, Typography } from '@mui/material';
import { withTransientProps } from '../../data/theme/withTransientProps';

export const Container = styled(Box)`
  display: grid;
  grid-template-columns: auto 70%;
  grid-template-rows: auto auto auto auto auto;
  gap: 10px 7px;
`;

export const Header = styled(Typography)`
  font-size: 12px;
  font-family: Roboto-Medium;
  color: #1f2126;
`;

export const Result = styled(Box, withTransientProps)<{ $isOpen: boolean }>`
  line-height: 1.5;
  font-size: 12px;
  font-family: Roboto-Regular;
  color: #1f2126;
  flex: 1;
  transition: 0.25s all ease-in;
  ${props => (props.$isOpen ? 'opacity: 1;' : 'opacity: 0;')}
`;
