import { View, Text } from '@react-pdf/renderer';
import { Styles } from './Table.styles';
import { PdfTableItem } from '../../interfaces';
import { IntlShape } from 'react-intl';

export interface TableProps {
  intl: IntlShape;
  data: PdfTableItem[];
}
export const Table = ({ intl, data }: TableProps) => {
  return (
    <View>
      <Text style={Styles.header}>
        {intl.formatMessage({
          id: 'export.pdf.table.header',
          defaultMessage: 'Results as table',
        })}
      </Text>
      <View style={Styles.tableHeader}>
        <Text style={{ ...Styles.firstColWidth, ...Styles.tableHeaderCell }}>
          {intl.formatMessage({
            id: 'export.pdf.table.table_header',
            defaultMessage: 'Address / zip code',
          })}
        </Text>
        <Text style={{ ...Styles.secondColWidth, ...Styles.tableHeaderCell }}>
          {intl.formatMessage({
            id: 'export.pdf.table.column.number_of_partners',
            defaultMessage: 'Number of partners',
          })}
        </Text>
        <Text style={{ ...Styles.thirdColWidth, ...Styles.tableHeaderCell }}>
          {intl.formatMessage({
            id: 'export.pdf.table.column.name',
            defaultMessage: 'Name',
          })}
        </Text>
        <Text style={{ ...Styles.fourthColWidth, ...Styles.tableHeaderCell }}>
          {intl.formatMessage({
            id: 'export.pdf.table.column.categories',
            defaultMessage: 'Categories',
          })}
        </Text>
        <Text style={{ ...Styles.fifthColWidth, ...Styles.tableHeaderCell }}>
          {intl.formatMessage({
            id: 'export.pdf.table.column.distance',
            defaultMessage: 'Distance',
          })}
        </Text>
      </View>

      <View style={Styles.tableBody}>
        {data.map((item, idx) => {
          return (
            <View style={Styles.tableRow} key={idx} break={idx === 10}>
              <Text style={{ ...Styles.firstColWidth, ...Styles.zipCode }}>{item.zipCode}</Text>
              <Text style={{ ...Styles.secondColWidth, ...Styles.column }}>
                {item.numberOfPartner}
              </Text>
              <Text style={{ ...Styles.thirdColWidth, ...Styles.column }}>{item.name}</Text>
              <Text style={{ ...Styles.fourthColWidth, ...Styles.column }}>{item.categories}</Text>
              <Text style={{ ...Styles.fifthColWidth, ...Styles.column, ...Styles.distance }}>
                {item.distance}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};
