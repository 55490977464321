import { XlsxRow } from './interfaces';
import { InputInformation, StudioGroupItem, StudioSortType } from '../../store/studio/interfaces';
import { getDistanceInKilometers, getResolvedCategories } from '../../store/studio/studio.utils';
import categoriesDe from '../../data/partners/categories/de/partner_categories.json';
import categoriesEn from '../../data/partners/categories/en/partner_categories.json';
import sortBy from 'lodash.sortby';

export const getXlsxData = (
  inputInformation: InputInformation[],
  studioGroups: StudioGroupItem[],
  language: string,
  sort: StudioSortType,
): XlsxRow[] => {
  const xlsxData: XlsxRow[] = [];

  inputInformation.forEach(item => {
    const group = studioGroups.find(group => group.inputInformationId === item.id);

    xlsxData.push({
      location: item.name,
      numberOfPartner: group ? group.visibleStudios.length : '',
      name: '',
      categories: '',
      nextPartner: '',
    });

    if (group) {
      const foundInformation = inputInformation.find(
        information => information.id === group.inputInformationId,
      );

      const visibleStudios = group.visibleStudios.map(studio => ({
        ...studio,
        distanceFromPosition: getDistanceInKilometers(
          { lat: studio.coordLat, lng: studio.coordLong },
          { lat: foundInformation?.lat!, lng: foundInformation?.lng! },
        ),
      }));

      const sortedVisibleStudios =
        sort === StudioSortType.Distance
          ? sortBy(visibleStudios, ['distanceFromPosition'], ['asc'])
          : sortBy(visibleStudios, ['studioName'], ['asc']);

      sortedVisibleStudios.forEach(studio => {
        const categories = getResolvedCategories(
          studio,
          language === 'de' ? categoriesDe : categoriesEn,
        );
        xlsxData.push({
          location: '',
          numberOfPartner: '',
          name: studio.studioName,
          categories,
          nextPartner:
            getDistanceInKilometers(
              { lat: studio.coordLat, lng: studio.coordLong },
              { lat: item.lat, lng: item.lng },
            ).toString() + ' km',
        });
      });
    }
  });

  return xlsxData;
};
