import { Box, Link, styled, Typography } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  height: 55px;
  background-image: linear-gradient(to bottom, #ffffff, #fbfbfb, #f7f7f7, #f4f4f4, #f0f0f0);
`;

export const Logo = styled('img')`
  align-self: flex-start;
  z-index: 2;
  height: 55px;
`;

export const TopBar = styled('div')(({ theme }) => ({
  height: '55px',
  backgroundColor: '#00194d',
  flex: 1,
}));

export const PageLink = styled(Link)`
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  font-size: 14px;
  color: #8e96a9;
  font-family: Roboto-Regular;
  text-decoration: none;
  line-height: 1.43;
  align-items: center;
`;

export const Separator = styled(Typography)`
  font-size: 14px;
  color: #6e7a93;
  font-family: Roboto-Regular;
  user-select: none;
  margin: 0 10px;
`;

export const LinkContainer = styled(Box)`
  justify-content: flex-end;
  padding-top: 18px;
  padding-right: 30px;
  display: flex;
  align-items center;
`;
