import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import {
  ButtonContainer,
  CloseButton,
  ExportSection,
  ModalContainer,
  RadioButton,
  StyledCheckbox,
  StyledDescription,
  StyledFormControlLabel,
  StyledHeader,
} from './ExportModal.styles';
import { FormControl, Modal, RadioGroup } from '@mui/material';
import closeIcon from '../../assets/icons/atoms-cta-close-blue.svg';
import { ExportButton } from '../Sidebar/Sidebar.styles';
import { useSelector } from 'react-redux';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { getXlsxData } from './ExportModal.utils';
import { getLanguage } from '../../utils/language.utils';
import { PdfDocument } from '../PdfExport/PdfDocument';
import { ExportType } from './interfaces';
import { useIntl } from 'react-intl';

export interface ExportModalProps {
  handleClose: () => void;
}

export const ExportModal = ({ handleClose }: ExportModalProps) => {
  const intl = useIntl();
  const [isPdfView, setIsPdfView] = useState(false);
  const language = getLanguage();
  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const studioGroups = useSelector(StudioSelectors.getStudioGroups);
  const [exportType, setExportType] = useState<ExportType>(ExportType.Pdf);
  const [additionalPages, setAdditionalPages] = useState<boolean>(true);
  const sort = useSelector(StudioSelectors.getSort);

  const handleExport = () => {
    if (exportType === ExportType.Xlsx) {
      handleXlsxExport();
    }

    if (exportType === ExportType.Pdf) {
      handlePdfExport();
    }
  };

  const handleXlsxExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      getXlsxData(inputInformation, studioGroups, language, sort),
    );
    worksheet['!cols'] = [
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];

    const workbook = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        intl.formatMessage({
          id: 'export.excel.header.address_and_zipcode',
          defaultMessage: 'Address / zip code',
        }),
        intl.formatMessage({
          id: 'export.excel.header.number_of_partners',
          defaultMessage: 'Number of partners',
        }),
        intl.formatMessage({
          id: 'export.excel.header.name',
          defaultMessage: 'Name',
        }),
        intl.formatMessage({
          id: 'export.excel.header.categories',
          defaultMessage: 'Categories',
        }),
        intl.formatMessage({
          id: 'export.excel.header.distance',
          defaultMessage: 'Distance',
        }),
      ],
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Export');
    XLSX.writeFile(
      workbook,
      `vp-explorer-export-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.xlsx`,
      { compression: true },
    );
  };

  const handlePdfExport = () => {
    setIsPdfView(true);
  };

  const handleClosePdfViewer = () => {
    setIsPdfView(false);
  };

  const handleExportType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportType(event.target.value as unknown as ExportType);
  };

  const handleAdditionalPages = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalPages(event.target.checked);
  };

  return (
    <Modal open={true}>
      <ModalContainer>
        <CloseButton onClick={handleClose}>
          <img src={closeIcon} alt='' />
        </CloseButton>
        <StyledHeader>
          {intl.formatMessage({
            id: 'export.modal.header',
            defaultMessage: 'Export result',
          })}
        </StyledHeader>
        <StyledDescription>
          {intl.formatMessage({
            id: 'export.modal.description',
            defaultMessage:
              'You can export your search result as XLSX or as PDF. Just click on the button, then the result will be exported and downloaded automatically. The generation of the PDF takes a few minutes.',
          })}
        </StyledDescription>
        <ExportSection>
          <FormControl>
            <RadioGroup value={exportType} name='export' onChange={handleExportType}>
              <StyledFormControlLabel
                value={ExportType.Xlsx}
                control={<RadioButton />}
                label={intl.formatMessage({
                  id: 'export.modal.export_as_xlsx',
                  defaultMessage: 'Export as XLSX',
                })}
              />
              <StyledFormControlLabel
                value={ExportType.Pdf}
                control={<RadioButton />}
                label={intl.formatMessage({
                  id: 'export.modal.export_as_pdf',
                  defaultMessage: 'Export as PDF',
                })}
              />
            </RadioGroup>
            <StyledFormControlLabel
              disabled={exportType === ExportType.Xlsx}
              control={
                <StyledCheckbox
                  disabled={exportType === ExportType.Xlsx}
                  checked={additionalPages}
                  onChange={handleAdditionalPages}
                />
              }
              label={intl.formatMessage({
                id: 'export.modal.add_online_offers',
                defaultMessage: 'Add online offers',
              })}
            />
          </FormControl>
        </ExportSection>
        <ButtonContainer>
          <ExportButton variant='contained' onClick={handleExport}>
            {intl.formatMessage({
              id: 'export.modal.export_result',
              defaultMessage: 'Export result',
            })}
          </ExportButton>
          {isPdfView && (
            <PdfDocument additionalPages={additionalPages} handleClose={handleClosePdfViewer} />
          )}
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};
