import { Box, styled, Tab, Tabs } from '@mui/material';

export const Container = styled('div')`
  flex: 1;
  width: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
    top: 237px;
    height: calc(100% - 289px);
  }

  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    top: 237px;
    height: calc(100% - 276px);
  }
`;

export const TabsContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-top: 1px solid #d8d8d8;
  background-color: #eff2f6;
  gap: 16px;
  justify-content: space-between;
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    gap: 30px;
  }

  .MuiTabs-indicator {
    bottom: 12px;
    height: 1px;
  }
`;
export const StyledTab = styled(Tab)`
  font-family: Roboto-Regular;
  font-size: 14px;
  color: #1f2126;
  text-transform: none;
  padding-left: 0;
  padding-right: 0;

  &.Mui-selected {
    font-family: Roboto-Bold;
    font-size: 14px;
    color: #003bb3;
    text-transform: none;
  }
`;
