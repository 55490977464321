import { Box, Button, Link, styled, Typography } from '@mui/material';

export const ResultsContainer = styled(Box)`
  overflow-y: scroll;
  display: flex;
  flex-flow: column;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const Header = styled(Typography)`
  font-size: 10px;
  font-family: Roboto-Regular;
  color: #1f2126;
`;

export const FirstColumnHeader = styled(Header)`
  margin-left: 61px;
  width: 195px;
`;

export const SecondColumnHeader = styled(Header)`
  width: 140px;
`;

export const ThirdColumnHeader = styled(Header)`
  flex: 1;
`;

export const GroupRow = styled(Box)`
  background-color: #eff2f6;
  border-radius: 8px;
  padding: 12px 0;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
`;

export const QuickInformation = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const DataContainer = styled(Box)`
  flex: 1 1 auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  gap: 10px;
  padding-top: 10px;
`;

export const StyledToggleButton = styled(Button)`
  padding: 0;
  margin: 0;
  min-width: auto;
`;
export const StyledToggleImage = styled('img')`
  margin-left: 10px;
`;

export const FirstColumnContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 236px;
  p {
    margin-left: 6px;
    font-family: Roboto-Bold;
    font-size: 15px;
  }
`;

export const SecondColumnContainer = styled(Box)`
  width: 140px;
  display: flex;
  align-items: center;

  p {
    font-family: Roboto-Bold;
    font-size: 15px;
    &:hover {
      color: #003bb3;
      cursor: pointer;
    }
  }
`;

export const ThirdColumnContainer = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;

  p {
    font-family: Roboto-Bold;
    font-size: 15px;
  }
`;
export const Divider = styled(Box)`
  height: 2px;
  margin: 8px 10px 0;
  background-color: white;
  width: auto;
`;

export const List = styled('ul')`
  list-style-type: none;
`;

export const ListItem = styled('li')`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const StudioListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const CategoryIcon = styled('img')`
  width: 18px;
  height: 18px;
`;

export const FirstColumnStudioListItem = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  p {
    font-size: 11px;
    font-family: Roboto-Regular;
  }
`;

export const StudioName = styled(Link)`
  width: 164px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 8px;
  font-family: Roboto-Regular;
  font-size: 11px;
  color: #1f2126;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    font-family: Roboto-Medium;
    color: #003bb3;
    text-decoration: underline;
  }
`;

export const SecondColumnStudioListItem = styled(Typography)`
  font-size: 11px;
  font-family: Roboto-Regular;
`;

export const InformationName = styled(Typography)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Placeholder = styled(Typography)`
  width: 36px;
`;
