import {
  Container,
  Icon,
  NoResultsContainer,
  NoResultsText,
  NoResultsTitle,
} from './NoResults.styles';
import { useIntl } from 'react-intl';
import noResults from '../../assets/icons/atoms-modal-no-studios.svg';

const NoResults = () => {
  const intl = useIntl();

  return (
    <NoResultsContainer>
      <Container>
        <Icon alt="" src={noResults} />

        <NoResultsTitle>
          {intl.formatMessage({
            id: 'no_results.title',
            defaultMessage: 'Unfortunately no studios found',
          })}
        </NoResultsTitle>
        <NoResultsText>
          {intl.formatMessage({
            id: 'no_results.description',
            defaultMessage: 'Change your filter settings to get search results.',
          })}
        </NoResultsText>
      </Container>
    </NoResultsContainer>
  );
};

export default NoResults;
