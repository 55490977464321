import { StyleSheet } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
  container: {
    display: 'flex',
  },
  secondSection: {
    marginLeft: '50px',
    marginRight: '158px',
  },
  secondInfoContainer: {
    flexDirection: 'column',
  },
  infoHeader: {
    fontSize: '16px',
    fontFamily: 'NutmegHeadline-Ultra',
    color: '#00194d',
    textTransform: 'uppercase',
  },
  liveInfoText: {
    fontSize: '12px',
    fontFamily: 'Roboto-Regular',
    color: '#1f2126',
    lineHeight: '1.5px',
    marginTop: '10px',
  },
});
