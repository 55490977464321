import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import { useIntl } from 'react-intl';
import debounce from 'lodash.debounce';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  InputAdornment,
  Tab,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ActiveFilter,
  ButtonContainer,
  ExportButton,
  FilterButton,
  FilterClientDescription,
  FilterContainer,
  FilterDescription,
  FilterForm,
  FilterIcon,
  FilterRow,
  FilterTitle,
  InfoAndSelectContainer,
  InfoButton,
  InfoIcon,
  InputContainer,
  InputCtaIconContainer,
  LocationIcon,
  ToggleHeightButton,
  MobileTabs,
  ShareResultButton,
  SidebarContainer,
  StyledAutocomplete,
  StyledTag,
  StyledTextField,
  TagContainer,
  ToggleButton,
  TopContainer,
  ResultFilterContainer,
} from './Sidebar.styles';
import { useDispatch, useSelector } from 'react-redux';
import { StudioActions } from '../../store/studio';
import { MapActions } from '../../store/map';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { FilterModal } from '../FilterModal/FilterModal';
import Selection from '../Selection/Selection';
import {
  getPlaceDetail,
  getPlacesPredictions,
  getPostalcodeOptionsBySearchValue,
  getStudioOptionsBySearchValue,
} from './Sidebar.utils';
import { AutocompleteOption, AutocompleteOptionType } from '../../models/AutocompleteOption';
import { MapSelectors } from '../../store/map/map.selectors';
import { useGoogleMap } from '@react-google-maps/api';
import { StudioModel } from '../../models/StudioModel';
import { getCategoryById } from '../../store/studio/studio.utils';
import Slider from '../Slider/Slider';
import { PageSelectors } from '../../store/page/page.selectors';
import { PageActions } from '../../store/page';
import { ExportModal } from '../ExportModal/ExportModal';
import { InfoModal } from '../InfoModal/InfoModal';
import { InputInformation } from '../../store/studio/interfaces';
import { ChooseFile } from '../ChooseFile/ChooseFile';
import { ShareModal } from '../ShareModal/ShareModal';
import { arrayUnique, hasAnyResult } from '../../utils/results.utils';
import sidebarOpenIcon from '../../assets/icons/atoms-cta-back.svg';
import infoIcon from '../../assets/icons/atoms-icons-info.svg';
import closeIcon from '../../assets/icons/close.svg';
import searchMarkerIcon from '../../assets/icons/search-marker.svg';
import filterIconOpen from '../../assets/icons/atoms-icons-filter-active.svg';
import { ResultFilter } from '../ClientFilterHeader/ResultFilter';
// https://github.com/zauberware/postal-codes-json-xml-csv/blob/master/data/DE.zip
import OrteUndBundeslaender from '../../data/zipcodes.de.json';
import useAllVisibleStudios from '../../hooks/useAllVisibleStudios';
import { SpinnerLoader } from '../SpinnerLoader/SpinnerLoader';

const Sidebar = () => {
  const isClient = process.env.REACT_APP_TYPE === 'client';
  const isSales = process.env.REACT_APP_TYPE === 'sales';
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const studioGroups = useSelector(StudioSelectors.getStudioGroups);
  const [mobileTabValue, setMobileTabValue] = useState<number>(0);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<AutocompleteOption[]>([]);
  const [isFiltersModuleActive, setIsFiltersModuleActive] = useState<boolean>(false);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const map = useGoogleMap();

  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const allCategories = useSelector(StudioSelectors.getCategories);
  const filter = useSelector(StudioSelectors.getFilter);
  const myLocation = useSelector(MapSelectors.getMyLocation);
  const sidebarOpen = useSelector(PageSelectors.getSidebar);
  const toggleIcon = sidebarOpenIcon;
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const autoCompleteRef = useRef<HTMLElement>(null);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isMaxHeight, setIsMaxHeight] = useState(false);
  const { data: allVisibleStudios = [] } = useAllVisibleStudios();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const studioIds = studioGroups
      .flatMap(studio => studio.visibleStudios)
      .map(studio => studio.publicId);

    dispatch(StudioActions.highlightStudios(studioIds));
  }, [studioGroups, dispatch]);

  const handleFile = async (acceptedFiles: File[]) => {
    const hasAlreadyFile = inputInformation.find(item => item.fileId.length > 0);

    if (!hasAlreadyFile && map) {
      acceptedFiles.map(async (file: File) => {
        const workBook = XLSX.read(new Uint8Array(await file.arrayBuffer()), { type: 'buffer' });
        const workBookName = workBook.SheetNames[0];
        const sheet = workBook.Sheets[workBookName];
        const sheetAsJson = XLSX.utils.sheet_to_json(sheet);
        const zipCodes = (sheetAsJson as Record<string, string>[]).map(
          item => item[Object.keys(item).find(key => key.toUpperCase().includes('PLZ'))!],
        );

        const fileId = uuidv4();

        const autoCompleteOption: AutocompleteOption = {
          name: file.name,
          lat: 0,
          lng: 0,
          type: AutocompleteOptionType.File,
          fileId,
          filename: file.name,
          zipCodes: arrayUnique(zipCodes),
        };

        setSelectedOptions([...selectedOptions, autoCompleteOption]);

        const inputInformationList = zipCodes.filter(Boolean).map(async zipCode => {
          const predictions = await getPlacesPredictions(zipCode, [
            'locality',
            'route',
            'street_address',
            'postal_code',
            'administrative_area_level_1',
          ]);

          const id = uuidv4();

          if (!predictions || predictions.length === 0) {
            return {
              id,
              name: zipCode,
              placeId: 'Unknown',
              lat: 0,
              lng: 0,
              fileId,
              filename: file.name,
            } as InputInformation;
          }

          const inputInformation: InputInformation = {
            id,
            name: zipCode,
            placeId: predictions[0].placeId ? predictions[0].placeId : '',
            lat: predictions[0].lat,
            lng: predictions[0].lng,
            fileId,
            filename: file.name,
            predictionTypes: predictions[0].predictionTypes,
          };

          const { lat, lng } = await getPlaceDetail(map, inputInformation.placeId, uuidv4());

          inputInformation.lat = lat;
          inputInformation.lng = lng;

          return inputInformation;
        });

        const resolvedInputInformationList = await Promise.all(inputInformationList);

        const updatedInputInformation = [...inputInformation, ...resolvedInputInformationList];
        dispatch(StudioActions.setInputInformation(updatedInputInformation));

        dispatch(
          StudioActions.filterVisibleStudioGroupItems({
            inputInformation: updatedInputInformation,
            type: AutocompleteOptionType.Prediction,
            filter,
            radius,
            allVisibleStudios,
          }),
        );
        return file;
      });
    }
  };

  const { getRootProps } = useDropzone({ onDrop: handleFile });
  const activeFilter =
    filter.servicePackages.length +
    filter.categories.length +
    (filter.checkInCard ? 1 : 0) +
    (filter.checkInApp ? 1 : 0) +
    (filter.hansefitCard ? 1 : 0) +
    filter.equipmentAndServices.length;

  const handleToggle = () => {
    if (sidebarOpen) {
      dispatch(PageActions.toggleSidebar(false));
      localStorage.removeItem('sidebar-open');
    } else {
      dispatch(PageActions.toggleSidebar(true));
      localStorage.setItem('sidebar-open', 'true');
    }
  };

  const handleToggleHeight = () => {
    setIsMaxHeight(!isMaxHeight);
  };

  const handleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const handleShareModal = () => {
    setIsShareModalOpen(!isShareModalOpen);
  };

  const handleInfoModal = () => {
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleSearchQueryChange = async (
    _: SyntheticEvent<Element, Event>,
    query: string,
    reason: string,
  ) => {
    if ((query === '' && reason === 'clear') || (query === '' && reason === 'input')) {
      clearInputValue();
      if (myLocation) {
        const myLocationAutoCompleteOption: AutocompleteOption = {
          name: myLocation.name,
          lat: myLocation.lat,
          lng: myLocation.lng,
          type: AutocompleteOptionType.CurrentLocation,
        };
        setOptions([myLocationAutoCompleteOption]);
      } else {
        setOptions([]);
      }
    } else if (query !== '' && reason === 'input' && map) {
      dispatch(StudioActions.setQuery(query));

      if (query.length >= 3) {
        debounceSearchQueryChange.current(query);
      }

      if (query.length < 3 && myLocation) {
        const myLocationAutoCompleteOption: AutocompleteOption = {
          name: myLocation.name,
          lat: myLocation.lat,
          lng: myLocation.lng,
          type: AutocompleteOptionType.CurrentLocation,
        };
        setOptions([myLocationAutoCompleteOption]);
      }
    }
  };

  const handlePredictions = async (searchValue: string) => {
    let predictions = await getPlacesPredictions(searchValue, [
      'locality',
      'route',
      'street_address',
      'postal_code',
      'administrative_area_level_1',
    ]);

    const filteredStudios = getStudioOptionsBySearchValue(searchValue, studioGroups);
    const filteredPostalCodes = getPostalcodeOptionsBySearchValue(searchValue);

    // has some of the federal states, because google doesn't detect all of them
    const foundFederalStatesInQuery = OrteUndBundeslaender.find(f =>
      f.state.toLowerCase().includes(searchValue.replace(', Deutschland', '').trim().toLowerCase()),
    );

    if (foundFederalStatesInQuery) {
      predictions = predictions.map(prediction => {
        if (
          prediction.name
            .replace(', Deutschland', '')
            .trim()
            .toLowerCase()
            .includes(foundFederalStatesInQuery.state.toLowerCase()) &&
          !prediction?.predictionTypes?.includes('administrative_area_level_1')
        ) {
          // add federal state to predictionTypes
          return {
            ...prediction,
            predictionTypes: [
              ...(prediction?.predictionTypes || []),
              'administrative_area_level_1',
            ],
          };
        }

        return prediction;
      });
    }

    setOptions([...predictions, ...filteredStudios, ...filteredPostalCodes]);
  };

  const debounceSearchQueryChange = useRef(debounce(handlePredictions, 500));

  const handleDeleteInputInformation = () => {
    setSelectedOptions([]);
    dispatch(StudioActions.setInputInformation([]));
    dispatch(StudioActions.setStudioGroups([]));
    dispatch(StudioActions.resetFilters());
  };

  const clearInputValue = () => {
    dispatch(StudioActions.clearSearchFilters());

    dispatch(MapActions.setSelectedLocation(null));
    dispatch(StudioActions.unselectStudio());
    dispatch(StudioActions.setRadiusFilterValue(50));
    handleDeleteInputInformation();
  };

  const handleOptionSelected = async (
    _: SyntheticEvent<Element, Event>,
    _1: unknown,
    reason: AutocompleteChangeReason,
    selectedOption: AutocompleteChangeDetails<unknown> | undefined,
  ) => {
    if (reason === 'selectOption' && selectedOption) {
      setLoading(true);

      const autocompleteOption = selectedOption.option as AutocompleteOption;
      let updatedInputInformation: InputInformation[] = [];

      if (!map) {
        return;
      }

      try {
        if (
          autocompleteOption.type === AutocompleteOptionType.ZipCode &&
          autocompleteOption.zipCodes &&
          autocompleteOption.zipCodes.length > 0
        ) {
          const inputInformationList = autocompleteOption.zipCodes.map(async zipCode => {
            const predictions = await getPlacesPredictions(zipCode, [
              'locality',
              'route',
              'street_address',
              'postal_code',
              'administrative_area_level_1',
            ]);

            const id = uuidv4();

            if (!predictions || predictions.length === 0 || !map) {
              return {
                id,
                name: zipCode,
                placeId: 'Unknown',
                lat: 0,
                lng: 0,
                fileId: autocompleteOption.name,
                filename: '',
              } as InputInformation;
            }

            const inputInformation: InputInformation = {
              id,
              name: zipCode,
              placeId: predictions[0].placeId ? predictions[0].placeId : '',
              lat: predictions[0].lat,
              lng: predictions[0].lng,
              fileId: autocompleteOption.name,
              filename: '',
              predictionTypes: predictions[0].predictionTypes,
            };

            const { lat, lng } = await getPlaceDetail(map, inputInformation.placeId, uuidv4());

            inputInformation.lat = lat;
            inputInformation.lng = lng;

            return inputInformation;
          });

          const resolvedInputInformationList = await Promise.all(inputInformationList);
          updatedInputInformation = [...inputInformation, ...resolvedInputInformationList];
        } else {
          const newInputInformation = {
            id: uuidv4(),
            name: autocompleteOption.name,
            placeId: autocompleteOption.placeId ? autocompleteOption.placeId : '',
            lat: 0,
            lng: 0,
            fileId: autocompleteOption.fileId ? autocompleteOption.fileId : '',
            filename: autocompleteOption.filename ? autocompleteOption.filename : '',
            predictionTypes: autocompleteOption.predictionTypes,
          };

          const { lat, lng } = await getPlaceDetail(
            map,
            newInputInformation.placeId,
            newInputInformation.id,
          );

          newInputInformation.lat = lat;
          newInputInformation.lng = lng;

          updatedInputInformation = [newInputInformation, ...inputInformation];
        }

        dispatch(StudioActions.setInputInformation(updatedInputInformation));

        const updatedSelectedOptions = [autocompleteOption, ...selectedOptions];
        setSelectedOptions(updatedSelectedOptions);

        dispatch(
          StudioActions.filterVisibleStudioGroupItems({
            inputInformation: updatedInputInformation,
            type: AutocompleteOptionType.Prediction,
            filter,
            radius,
            allVisibleStudios,
          }),
        );

        if (autoCompleteRef.current) {
          autoCompleteRef.current.blur();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleFilterModal = () => {
    if (isFiltersModuleActive) {
      setIsFiltersModuleActive(false);
      localStorage.setItem('sidebar-filters-modal', 'true');
    } else {
      setIsFiltersModuleActive(true);
      localStorage.removeItem('sidebar-filters-modal');
    }
  };

  const handleDeleteOption = (autocompleteOption: AutocompleteOption) => {
    const foundInputInformation = inputInformation.find(
      inputInformation => inputInformation.placeId === autocompleteOption.placeId,
    );

    const isPredictionType = autocompleteOption.type === AutocompleteOptionType.Prediction;
    const isFile = autocompleteOption.type === AutocompleteOptionType.File;
    const isZipCode = autocompleteOption.type === AutocompleteOptionType.ZipCode;

    if (isPredictionType && foundInputInformation) {
      const { id } = foundInputInformation;

      const filteredInputInformation = inputInformation.filter(
        inputInformation => inputInformation.placeId !== autocompleteOption.placeId,
      );

      dispatch(StudioActions.setInputInformation(filteredInputInformation));

      const filteredStudioGroups = studioGroups.filter(
        studioGroup => studioGroup.inputInformationId !== id,
      );

      dispatch(StudioActions.setStudioGroups(filteredStudioGroups));
    }

    if (isFile || isZipCode) {
      const filteredInputInformation = inputInformation.filter(
        inputInformation => inputInformation.fileId !== autocompleteOption.fileId,
      );

      dispatch(StudioActions.setInputInformation(filteredInputInformation));

      const filteredStudioGroups = studioGroups.filter(
        studioGroup => studioGroup.fileId !== autocompleteOption.fileId,
      );

      dispatch(StudioActions.setStudioGroups(filteredStudioGroups));
    }

    const updatedOptions = selectedOptions.filter(
      selectedOption => selectedOption.placeId !== autocompleteOption.placeId,
    );
    setSelectedOptions(updatedOptions);
  };

  return (
    <SidebarContainer
      $isOpen={isSmall ? true : sidebarOpen}
      $showList={studioGroups.length > 0}
      $listTabOpen={Boolean(mobileTabValue)}
      $hasLocation={!!myLocation}>
      <FilterContainer $isMaxHeight={isMaxHeight} $isClient={isClient} $isSales={isSales}>
        <TopContainer>
          <Box>
            {isSales && (
              <FilterTitle>
                {intl.formatMessage({
                  id: 'title.sales',
                  defaultMessage: 'Verbundpartner Explorer',
                })}
              </FilterTitle>
            )}
            {isClient && (
              <FilterTitle>
                {intl.formatMessage({
                  id: 'title.client',
                  defaultMessage: 'Hansefit Verbund-partner Search',
                })}
              </FilterTitle>
            )}
            {isSales && (
              <FilterDescription $marginBottom='25px' $isMaxHeight={isMaxHeight}>
                {intl.formatMessage({
                  id: 'filter.sales.description',
                  defaultMessage:
                    'You can use the search box to enter single or multiple zip codes, as well as upload an Excel list.',
                })}
              </FilterDescription>
            )}
            {isClient && (
              <FilterClientDescription $marginBottom='10px' $isMaxHeight={isMaxHeight}>
                {intl.formatMessage({
                  id: 'filter.client.description',
                  defaultMessage: 'Here is the result of the individual partner search.',
                })}
              </FilterClientDescription>
            )}
          </Box>
          <ToggleHeightButton onClick={handleToggleHeight} $isMaxHeight={isMaxHeight}>
            <img src={toggleIcon} alt='' />
          </ToggleHeightButton>
          <ToggleButton onClick={handleToggle} $isOpen={sidebarOpen}>
            <img src={toggleIcon} alt='' />
          </ToggleButton>
        </TopContainer>
        {isClient && (
          <ResultFilterContainer $isMaxHeight={isMaxHeight}>
            <ResultFilter />
          </ResultFilterContainer>
        )}
        {isSales && (
          <FilterForm $isOpen={isSmall ? true : sidebarOpen} $isMaxHeight={isMaxHeight}>
            <FilterRow>
              <StyledAutocomplete
                {...getRootProps()}
                multiple
                clearOnBlur
                blurOnSelect='touch'
                ref={autoCompleteRef}
                freeSolo
                value={selectedOptions}
                disablePortal
                size='small'
                renderTags={(tags: unknown) => {
                  const autocompleteOptions = tags as AutocompleteOption[];
                  const threshold = 30;

                  let currentLength = 1;

                  return (
                    <TagContainer>
                      {autocompleteOptions.map((autocompleteOption, idx) => {
                        currentLength =
                          currentLength +
                          (autocompleteOption.displayName
                            ? autocompleteOption.displayName
                            : autocompleteOption.name
                          ).length;

                        return currentLength < threshold ? (
                          <StyledTag
                            onClick={() => handleDeleteOption(autocompleteOption)}
                            key={idx}>
                            <>
                              {autocompleteOption.displayName
                                ? autocompleteOption.displayName
                                : autocompleteOption.name}
                            </>
                          </StyledTag>
                        ) : null;
                      })}

                      {currentLength >= threshold ? <>...</> : null}
                    </TagContainer>
                  );
                }}
                onInputChange={handleSearchQueryChange}
                onChange={handleOptionSelected}
                filterOptions={x => x}
                options={[...options]}
                getOptionLabel={(option: unknown) => {
                  if (typeof option === 'string') {
                    return option;
                  } else {
                    return (option as AutocompleteOption).name;
                  }
                }}
                renderOption={(props, option: any) => {
                  const category = getCategoryById(
                    allCategories,
                    (option as StudioModel).categoryPrimary,
                  );
                  const studio = option as StudioModel;
                  const id = uuidv4();

                  if (studio.categoryPrimary) {
                    return (
                      <li {...props} className={props.className + ' autocomplete-option'} key={id}>
                        <div className='option-partner-category-icon'>
                          {category.iconUrlWithoutCircle && (
                            <img src={category.iconUrlWithoutCircle} alt='' />
                          )}
                        </div>
                        {option.name}
                      </li>
                    );
                  }

                  return (
                    <li {...props} className={props.className + ' autocomplete-option'} key={id}>
                      <img src={searchMarkerIcon} alt='' />
                      {option.name}
                    </li>
                  );
                }}
                renderInput={params => (
                  <InputContainer>
                    <LocationIcon src={searchMarkerIcon} alt='' />
                    <StyledTextField
                      {...params}
                      placeholder={intl.formatMessage({
                        id: 'sidebar.search_placeholder',
                        defaultMessage:
                          'City or postal code (e.g. 28201, 28205,...), optionally street',
                      })}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position='end'>
                            {inputInformation.length > 0 && (
                              <InputCtaIconContainer onClick={handleDeleteInputInformation}>
                                <img src={closeIcon} alt='' />
                              </InputCtaIconContainer>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputContainer>
                )}
              />
              <FilterButton onClick={toggleFilterModal}>
                {activeFilter > 0 && <ActiveFilter>{activeFilter}</ActiveFilter>}
                <FilterIcon alt='' src={filterIconOpen} />
              </FilterButton>
            </FilterRow>
            <InfoAndSelectContainer>
              <ChooseFile onDrop={handleFile} />
              <InfoButton variant='text' onClick={handleInfoModal}>
                <InfoIcon alt='' src={infoIcon} />
                {intl.formatMessage({
                  id: 'info.export.excel',
                  defaultMessage: 'Info about the creation of the Excel list',
                })}
              </InfoButton>
            </InfoAndSelectContainer>
            <FilterRow>
              <Slider />
            </FilterRow>
            {hasAnyResult(studioGroups) && (
              <ButtonContainer sx={{ alignItems: 'stretch' }}>
                <ExportButton
                  variant='contained'
                  onClick={handleExportModal}
                  sx={{ width: '100%' }}>
                  {intl.formatMessage({
                    id: 'cta.export.result',
                    defaultMessage: 'Export result',
                  })}
                </ExportButton>
                <ShareResultButton
                  variant='outlined'
                  onClick={handleShareModal}
                  sx={{ width: '100%' }}>
                  {intl.formatMessage({
                    id: 'cta.share.result',
                    defaultMessage: 'Share result',
                  })}
                </ShareResultButton>
                <ShareResultButton
                  variant='outlined'
                  onClick={clearInputValue}
                  sx={{ width: '100%' }}>
                  {intl.formatMessage({
                    id: 'filters.reset',
                    defaultMessage: 'Reset filter',
                  })}
                </ShareResultButton>
              </ButtonContainer>
            )}
          </FilterForm>
        )}
        {loading && <SpinnerLoader />}
      </FilterContainer>

      {!isSmall && sidebarOpen && studioGroups.length > 0 && <Selection />}
      {isSmall && (
        <>
          <MobileTabs
            value={mobileTabValue}
            onChange={(_, value) => setMobileTabValue(value)}
            variant='fullWidth'>
            <Tab
              label={intl.formatMessage({
                id: 'tabs.map_view',
                defaultMessage: 'Map view',
              })}
              value={0}
            />
            <Tab
              label={intl.formatMessage({
                id: 'tabs.list_view',
                defaultMessage: 'List view',
              })}
              value={1}
            />
          </MobileTabs>
          {mobileTabValue === 1 ? <Selection /> : <></>}
        </>
      )}

      {isFiltersModuleActive && <FilterModal closeModal={toggleFilterModal} />}
      {isExportModalOpen && <ExportModal handleClose={handleExportModal} />}
      {isInfoModalOpen && <InfoModal handleClose={handleInfoModal} />}
      {isShareModalOpen && <ShareModal handleClose={handleShareModal} />}
    </SidebarContainer>
  );
};

export default Sidebar;
