import { getLanguage } from '../utils/language.utils';
import { useMemo } from 'react';
import { usePartnerListQuery } from '../store/actions/async/getPartnerList/getPartnerList';

const useAllVisibleStudios = () => {
  const language = useMemo(() => getLanguage(), []);

  return usePartnerListQuery({ language });
};

export default useAllVisibleStudios;
