import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { InputInformation, StudioFilter, StudioGroupItem } from '../../studio/interfaces';

const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT;

interface ShareResults {
  studios: {
    inputInformation: InputInformation[];
    studioGroups: StudioGroupItem[];
    filter: StudioFilter;
    radiusFilterValue: number;
  };
  timestamp: number;
}

export const shareResultsApi = createApi({
  reducerPath: 'shareResults',
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  // In case we want to use cognito authentication
  // baseQuery: fetchBaseQuery({
  //   baseUrl: apiBaseUrl,
  //   prepareHeaders: async headers => {
  //     const currentSession = await Auth.currentSession();

  //     const token = currentSession.getIdToken().getJwtToken();
  //     // If we have a token set in state, let's assume that we should be passing it.
  //     if (token) {
  //       headers.set('authorization', `Bearer ${currentSession.getIdToken().getJwtToken()}`);
  //     }
  //     return headers;
  //   },
  // }),
  endpoints: builder => ({
    getShareResultsById: builder.query<ShareResults, { id: string }>({
      query: ({ id }) => ({
        url: `/store/${id}`,
        method: 'GET',
      }),
    }),
    updateShareResults: builder.mutation<ShareResults, { id: string; data: Partial<ShareResults> }>(
      {
        query: ({ id, data }) => ({
          headers: {
            Authorization: `Basic ${window.btoa(
              `${process.env.REACT_APP_BASIC_AUTH_USERNAME}:${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
            )}`,
          },
          url: `/store/${id}`,
          method: 'PUT',
          body: { ...data, timestamp: new Date().getTime() },
        }),
      },
    ),
  }),
});

export const { useGetShareResultsByIdQuery, useUpdateShareResultsMutation } = shareResultsApi;
