import { StyleSheet } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
  header: {
    marginLeft: '49px',
    marginTop: '30px',
    fontFamily: 'Roboto-Bold',
    textTransform: 'uppercase',
    fontSize: '14px',
  },
  tableHeader: {
    backgroundColor: '#003bb3',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    width: '526px',
    height: '40px',
    marginTop: '10px',
    marginLeft: '35px',
    marginRight: '35px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '11px',
  },
  firstColWidth: {
    width: '136px',
  },
  secondColWidth: {
    width: '46px',
  },
  thirdColWidth: {
    width: '120px',
  },
  fourthColWidth: {
    width: '128px',
  },
  fifthColWidth: {
    flex: '1',
  },
  tableHeaderCell: {
    fontFamily: 'Roboto-Medium',
    fontSize: '12px',
    color: '#ffffff',
  },
  tableBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '526px',
    marginLeft: '35px',
    marginRight: '35px',
    gap: '1px',
  },
  tableRow: {
    backgroundColor: '#eff2f6',
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    alignItems: 'flex-start',
  },
  zipCode: {
    marginLeft: '2px',
    fontFamily: 'Roboto-Medium',
    fontSize: '10px',
    color: '#1f2126',
  },
  column: {
    marginLeft: '4px',
    fontFamily: 'Roboto-Regular',
    fontSize: '10px',
    color: '#1f2126',
  },

  distance: {
    textAlign: 'right',
    marginRight: '16px',
  },
});
