import { MapState } from './interfaces';

export const initialState: MapState = {
  map: null,
  center: null,
  distance: null,
  selectedLocation: null,
  myLocation: null,
  isDefaultView: true,
};
