import { StyleSheet } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#eff2f6',
  },
  header: {
    backgroundColor: '#00194d',
    width: '100%',
    height: '35px',

  },
  logo: {
    width: '180px',
    borderBottom: 'none',
    objectFit: 'contain',
    height: '35px',
  },
  headerText: {
    fontFamily: 'NutmegHeadline-Ultra',
    color: '#00194d',
    fontSize: '24px',
    textTransform: 'uppercase',
    marginTop: '40px',
    marginLeft: '49px',
  },
  descriptionText: {
    fontFamily: 'Roboto-Regular',
    fontSize: '10px',
    color: '#1f2126',
    marginTop: '10px',
    marginLeft: '50px',
  },
});
