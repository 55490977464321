import { AutocompleteOption, AutocompleteOptionType } from '../../models/AutocompleteOption';
import { PlaceDetail, StudioGroupItem } from '../../store/studio/interfaces';
// https://github.com/zauberware/postal-codes-json-xml-csv/blob/master/data/DE.zip
import OrteUndBundeslaender from '../../data/zipcodes.de.json';
import { v4 as uuidv4 } from 'uuid';
import { arrayUnique } from '../../utils/results.utils';

export const getPlacesPredictions = async (
  query: string,
  types: string[],
): Promise<AutocompleteOption[]> => {
  const autocompleteService = new window.google.maps.places.AutocompleteService();

  const response = await autocompleteService.getPlacePredictions({
    input: query,
    language: 'de',
    types,
    componentRestrictions: { country: ['de', 'lu'] },
  });

  const placePredictions: AutocompleteOption[] = [];
  const predictions = response?.predictions?.slice(0, 4) || [];

  predictions.forEach((prediction: google.maps.places.AutocompletePrediction) => {
    placePredictions.push({
      name: prediction.description,
      displayName: prediction.structured_formatting.main_text,
      placeId: prediction.place_id,
      lat: 0,
      lng: 0,
      type: AutocompleteOptionType.Prediction,
      predictionTypes: prediction.types,
    });
  });

  return placePredictions;
};

export const getPlaceDetail = (
  map: google.maps.Map,
  placeId: string,
  id: string,
): Promise<PlaceDetail> =>
  new Promise((resolve, reject) => {
    const placesService = new window.google.maps.places.PlacesService(map);
    placesService.getDetails({ placeId, language: 'de' }, (placeResult, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && placeResult) {
        resolve({
          id,
          lat: placeResult.geometry?.location?.lat()!,
          lng: placeResult.geometry?.location?.lng()!,
        });
      } else {
        reject(status);
      }
    });
  });

export const getStudioOptionsBySearchValue = (
  query: string,
  studioGroups: StudioGroupItem[],
): AutocompleteOption[] => {
  const filteredOptions: AutocompleteOption[] = [];
  studioGroups.forEach(groupItem => {
    groupItem.visibleStudios.forEach(studio => {
      const { studioName, coordLat, coordLong, publicId } = studio;
      if (studioName.toLowerCase().includes(query.toLowerCase())) {
        filteredOptions.push({
          name: studioName,
          lat: coordLat,
          lng: coordLong,
          type: AutocompleteOptionType.Studio,
          publicId,
        });
      }
    });
  });

  return filteredOptions.slice(0, 4);
};

export const getFilteredOptions = (query: string, options: AutocompleteOption[]) => {
  const filteredOptions: AutocompleteOption[] = [];

  if (query.length > 2) {
    options.forEach(option => {
      if (option.name.toLowerCase().includes(query.trim().replace(/.$/, '').toLowerCase())) {
        filteredOptions.push(option);
      }
    });
  }

  return filteredOptions.slice(0, 4);
};

export const getPostalcodeOptionsBySearchValue = (query: string): AutocompleteOption[] => {
  const filteredOptions: AutocompleteOption[] = [];

  if (query.match(/^\d{2}\*$/)) {
    const zipCodes = arrayUnique(
      OrteUndBundeslaender.filter(item => item.zipcode.startsWith(query.replace('*', ''))).map(
        item => item.zipcode,
      ),
    );

    filteredOptions.push({
      name: query,
      type: AutocompleteOptionType.ZipCode,
      zipCodes,
      lat: 0,
      lng: 0,
      placeId: uuidv4(),
      fileId: query,
    });
  }

  return filteredOptions.slice(0, 4);
};
