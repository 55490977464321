import { styled } from '@mui/material';

export const MemberInformationContainerBest = styled('div')`
  letter-spacing: 0.11px;
  line-height: 1.5;
  font-family: Roboto-Medium;
  font-size: 12px;
  color: #1f2126;
  font-weight: 500;
  max-width: 300px;
  margin-left: 10px;
  margin-top: -7px;
`;

export const MemberInformationContainerPro = styled('div')`
  letter-spacing: 0.11px;
  line-height: 1.5;
  font-family: Roboto-Medium;
  font-size: 12px;
  color: #1f2126;
  font-weight: 500;
  max-width: 300px;
  margin-left: 16px;
  margin-top: -2px;
`;

export const MemberInformationContainerBasic = styled('div')`
  letter-spacing: 0.11px;
  line-height: 1.5;
  font-family: Roboto-Medium;
  font-size: 12px;
  color: #1f2126;
  font-weight: 500;
  max-width: 300px;
  margin-left: 4.3px;
  margin-top: -2px;
  margin-bottom: 25px;
`;
export const MembershipIconContainer = styled('div')`
  border: 1px solid #d8d8d8;
  border-radius: 32px;
  text-transform: capitalize;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  padding-right: 16px;
  font-family: Roboto-Regular;
  font-size: 12px;
`;

export const MembershipIcon = styled('img')`
  width: auto;
  height: 22px;
  object-fit: cover;
  margin-right: 4px;
`;

export const MemberContainer = styled('div')`
  display: flex;
  margin-top: 20px;
  gap: 10px;
`;

export const InfoTextContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.5;
  letter-spacing: 0.11px;
  font-family: Roboto;
  font-size: 12px;
  color: #1f2126;
  margin-top: 27px;
  margin-bottom: -17px;
`;
