import { StyleSheet } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
  background: {
    marginTop: '212px',
    position: 'absolute',
    zIndex: -1,
  },
  secondPageBackground: {
    marginTop: '34px',
    position: 'absolute',
    zIndex: -1,
  },
  container: {
    position: 'relative',
    top: '-30px',
  },
  textStyles: {
    fontSize: '8px',
    fontFamily: 'Roboto-Regular',
    color: '#1f2126',
    marginLeft: '50px',
  },
  linkStyles: {
    fontSize: '8px',
    fontFamily: 'Roboto-Medium',
  },
  secondContainer: {
    position: 'relative',
    marginTop: '7px',
  },
  thirdContainer: {
    position: 'relative',
  },
  logo: {
    width: '100px',
    left: '419px',
    marginBottom: '51px',
  },
  upContainer: {
    position: 'relative',
    top: '-40px',
  },
});
