import { StyleSheet } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F5F5F5',
    padding: '20px 50px',
    marginTop: '20px',
  },
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: '14px',
    color: '#1f2126',
    textTransform: 'uppercase',
    marginBottom: '13px',
  },
  firstCol: {
    color: '#1f2126',
    fontFamily: 'Roboto-Medium',
    fontSize: '10px',
    width: '96px',
  },
  secondCol: {
    flex: '1',
    fontFamily: 'Roboto-Regular',
    color: '#1f2126',
    fontSize: '10px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignContent: 'center',
  },
  rowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
  },
});
