import React from 'react';
import { Circle, Marker } from '@react-google-maps/api';
import locationIcon from '../../assets/map_marker/location-marker.png';
import { InputInformation } from '../../store/studio/interfaces';

const LocationMarkers = ({
  id,
  lng,
  lat,
  radius,
}: Pick<InputInformation, 'id' | 'lng' | 'lat'> & { radius: number }) => (
  <>
    <Circle
      key={`circle-${id}`}
      center={{ lat, lng }}
      radius={radius * 1000}
      options={{
        strokeColor: '#666666',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#666666',
        fillOpacity: 0.35,
      }}
    />
    <Marker
      zIndex={3}
      key={`marker-${id}`}
      icon={{ url: locationIcon, scaledSize: new window.google.maps.Size(20, 20) }}
      position={{ lng, lat }}
    />
  </>
);

export default LocationMarkers;
