import { Button, styled, Typography } from '@mui/material';

export const SelectFileText = styled(Typography)`
  font-size: 12px;
  font-family: Roboto-Medium;
  color: #002a80;
  font-weight: 500;
`;

export const SelectFileButton = styled(Button)`
  border-radius: 4px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  background-color: #e8eef8;
  border-color: #e8eef8;
  font-size: 12px;
  font-family: Roboto-Medium;
  color: #012a80;
`;

export const UploadIcon = styled('img')`
  width: 16px;
  height: 16px;
`;
