import { StyleSheet } from '@react-pdf/renderer';

export const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    marginTop: '43px',
  },
  firstSection: {
    flexDirection: 'row',
    marginLeft: '50px',
    justifyContent: 'space-between',
  },
  infoContainer: {
    display: 'flex',
    width: '313x',
  },
  infoHeader: {
    fontSize: '16px',
    fontFamily: 'NutmegHeadline-Ultra',
    color: '#00194d',
    textTransform: 'uppercase',
  },
  liveInfoText: {
    fontSize: '12px',
    fontFamily: 'Roboto-Regular',
    color: '#1f2126',
    lineHeight: '1.5px',
    marginTop: '8px',
  },
  imageContainer: {
    display: 'flex',
    marginRight: '40px',
    position: 'relative',
    top: '-50px',
  },
  appLogo: {
    width: '202px',
  },
});
