import React from 'react';
import { Modal } from '@mui/material';
import CloseIcon from '../../assets/icons/atoms-cta-close-blue.svg';
import FormatSample from '../../assets/icons/format-sample.png';
import {
  CloseButton,
  ModalContainer,
  Description,
  Header,
  Subheader,
  InstructionContainer,
  FormatInstruction,
  StyledFormatSample,
} from './InfoModal.styles';
import { useIntl } from 'react-intl';

export interface InfoModalProps {
  handleClose: () => void;
}

export const InfoModal = ({ handleClose }: InfoModalProps) => {
  const intl = useIntl();

  return (
    <Modal open={true}>
      <ModalContainer>
        <CloseButton onClick={handleClose}>
          <img src={CloseIcon} alt="" />
        </CloseButton>
        <Header>
          {intl.formatMessage({
            id: 'info.modal.header',
            defaultMessage: 'Zip code as list',
          })}
        </Header>
        <Description>
          {intl.formatMessage({
            id: 'info.modal.description',
            defaultMessage: 'You can easily upload an Excel list with several postal codes.',
          })}
        </Description>
        <Subheader>
          {intl.formatMessage({
            id: 'info.modal.sub_header',
            defaultMessage: 'Excel List Creation Template/Declaration',
          })}
          :
        </Subheader>
        <InstructionContainer>
          <StyledFormatSample src={FormatSample} alt="" />
          <FormatInstruction>
            {intl.formatMessage({
              id: 'info.modal.instruction',
              defaultMessage:
                'Create a new document in Excel with a single column. In the first row, the heading must say "ZIP Code". Below that, there are your postal codes.',
            })}
          </FormatInstruction>
        </InstructionContainer>
      </ModalContainer>
    </Modal>
  );
};
