import { View, Image, Text } from '@react-pdf/renderer';
import logo from '../../../../assets/icons/hansefit-logo.png';
import { Styles } from './Header.styles';

export interface HeaderProps {
  headerText?: string;
  descriptionText?: string;
}

export const Header = ({ headerText, descriptionText }: HeaderProps) => {
  return (
    <View>
      <View style={Styles.header}>
        <Image src={logo} style={Styles.logo} />
      </View>
      {headerText && <Text style={Styles.headerText}>{headerText}</Text>}
      {descriptionText && <Text style={Styles.descriptionText}>{descriptionText}</Text>}
    </View>
  );
};
