export interface XlsxRow {
  location: string | number;
  numberOfPartner: string | number;
  name: string;
  categories: string;
  nextPartner: string;
}

export enum ExportType {
  Xlsx = 'xlsx',
  Pdf = 'pdf',
}
