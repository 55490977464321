import { StudioModelFiltered, StudioModelRaw } from '../models/StudioModel';
import { isStudioValid } from '../store/studio/studio.utils';

export const filterAndMapStudios = (studios: StudioModelRaw[]) => {
  const filteredAndMappedStudios = studios
    .filter(isStudioValid)
    .filter((studio, index, self) => {
      const unique = self.findIndex(s => s.publicId === studio.publicId) === index;
      return unique;
    })
    .map(studio => {
      const {
        notes,
        description,
        openingHours,
        holidays,
        showOpeningTimes,
        studioImage,
        video,
        pictures,
        fitnessServices,
        wellnessServices,
        services,
        ...rest
      } = studio;
      return rest as StudioModelFiltered;
    });

  return filteredAndMappedStudios;
};
