import { CaseReducer, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { StudioModel, StudioModelFiltered } from '../../models/StudioModel';
import { initialState } from './initialState';
import {
  StudioFilter,
  StudioState,
  StudioGroupItem,
  InputInformation,
  InputFilters,
  EquipmentAndServices,
  StudioSortType,
} from './interfaces';
import { AutocompleteOptionType } from '../../models/AutocompleteOption';
import { getFilteredStudios, getFilterToStateFilter } from './studio.utils';
import { Category } from '../../models/Category';

const setHover: CaseReducer<StudioState, PayloadAction<string | null>> = (state, action) => {
  state.hover = action.payload;
};

const setFilter: CaseReducer<StudioState, PayloadAction<StudioFilter>> = (state, action) => {
  state.filter = action.payload;
};
const setCategories: CaseReducer<StudioState, PayloadAction<Category[]>> = (state, action) => {
  state.categories = action.payload;
};
const setEquipmentAndServices: CaseReducer<StudioState, PayloadAction<EquipmentAndServices[]>> = (
  state,
  action,
) => {
  state.equipmentAndServices = action.payload;
};

const setVisibleStudios: CaseReducer<StudioState, PayloadAction<StudioModel[]>> = (
  state,
  action,
) => {
  state.visibleStudios = action.payload;
};

const setQuery: CaseReducer<StudioState, PayloadAction<string>> = (state, action) => {
  state.query = action.payload;
};

const setRadiusFilterValue: CaseReducer<StudioState, PayloadAction<number>> = (state, action) => {
  state.radiusFilterValue = action.payload;
};

const filterStudioById: CaseReducer<StudioState, PayloadAction<{ publicId: string }>> = (
  state,
  action,
) => {
  const { publicId } = action.payload;
  const studio = current(state).visibleStudios.find(studio => studio.publicId === publicId);

  if (studio) {
    state.visibleStudios = [studio];
  }
};

const filterVisibleStudioGroupItems: CaseReducer<
  StudioState,
  PayloadAction<{
    inputInformation: InputInformation[];
    type: AutocompleteOptionType;
    filter: StudioFilter;
    radius: number;
    allVisibleStudios: StudioModelFiltered[];
  }>
> = (state, action) => {
  const { inputInformation, filter, radius, allVisibleStudios } = action.payload;

  state.inputInformation = inputInformation;
  state.studioGroups = inputInformation.map(inputInformation => {
    const filteredStudios = getFilteredStudios({
      studios: allVisibleStudios,
      filter,
      radius,
      visibleStudioInputInformation: inputInformation,
    });

    return {
      inputInformationId: inputInformation.id,
      visibleStudios: filteredStudios,
      fileId: inputInformation.fileId,
    };
  });
};

const clearSearchFilters: CaseReducer<StudioState> = state => {
  state.visibleStudios = [];

  state.visibleStudios = state.visibleStudios.map(studio => {
    studio.distanceFromPosition = 0;

    return studio;
  });
  state.query = '';
  state.radiusFilterValue = 50;
};

const selectStudio: CaseReducer<StudioState, PayloadAction<StudioModelFiltered>> = (
  state,
  action,
) => {
  state.selected = action.payload;
};

const selectStudios: CaseReducer<StudioState, PayloadAction<string[]>> = (state, action) => {
  state.selectedStudios = action.payload;
};

const highlightStudios: CaseReducer<StudioState, PayloadAction<string[]>> = (state, action) => {
  state.highlightedStudios = action.payload;
};

const setSelectedStudioModalOpen: CaseReducer<StudioState, PayloadAction<boolean>> = (
  state,
  action,
) => {
  state.selectedStudioModalOpen = action.payload;
};

const unselectStudio: CaseReducer<StudioState> = state => {
  state.selected = null;
};

const applyFilters: CaseReducer<StudioState, PayloadAction<InputFilters>> = (state, action) => {
  state.filter = getFilterToStateFilter(action.payload);
};

const resetFilters: CaseReducer<StudioState> = state => {
  state.filter = { ...initialState.filter };
};

const setInputInformation: CaseReducer<StudioState, PayloadAction<InputInformation[]>> = (
  state,
  action,
) => {
  state.inputInformation = action.payload;
};

const setStudioGroups: CaseReducer<StudioState, PayloadAction<StudioGroupItem[]>> = (
  state,
  action,
) => {
  state.studioGroups = action.payload;
};

const setSort: CaseReducer<StudioState, PayloadAction<StudioSortType>> = (state, action) => {
  state.sort = action.payload;
};

export const studioSlice = createSlice({
  name: 'studios',
  initialState,
  reducers: {
    setQuery,

    setRadiusFilterValue,
    filterStudioById,
    clearSearchFilters,
    selectStudio,
    setSelectedStudioModalOpen,
    unselectStudio,
    applyFilters,
    resetFilters,
    setHover,
    setVisibleStudios,
    setCategories,
    setInputInformation,
    filterVisibleStudioGroupItems,
    setStudioGroups,
    selectStudios,
    highlightStudios,
    setFilter,
    setEquipmentAndServices,
    setSort,
  },
});

export const { name, actions } = studioSlice;

export default studioSlice.reducer;
