import { combineReducers } from '@reduxjs/toolkit';
import studioReducer from './studio/studioSlice';
import mapReducer from './map/mapSlice';
import pageReducer from './page/pageSlice';
import { shareResultsApi } from './api/shareResults/shareResultsApi';

const rootReducer = combineReducers({
  [shareResultsApi.reducerPath]: shareResultsApi.reducer,
  studios: studioReducer,
  map: mapReducer,
  page: pageReducer,
});

export default rootReducer;
