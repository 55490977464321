import { Modal } from '@mui/material';
import React from 'react';
import {
  CloseButton,
  ModalContainer,
  StyledDescription,
  StyledHeader,
} from './InformationModal.styles';

import filtersModalIcon from '../../assets/icons/close.svg';
import { FormattedMessage } from 'react-intl';

export interface CheckInInformationModalProps {
  handleClose: () => void;
}

export const CheckInInformationModal = ({ handleClose }: CheckInInformationModalProps) => {
  return (
    <Modal open={true}>
      <ModalContainer>
        <CloseButton onClick={handleClose}>
          <img src={filtersModalIcon} alt="" />
        </CloseButton>
        <StyledHeader>
          <FormattedMessage
            id={'filters.check_in.information.header'}
            defaultMessage={'Twogether trainingsprogram'}
          />
        </StyledHeader>
        <StyledDescription>
          <FormattedMessage
            id={'filters.check_in.information.description'}
            defaultMessage={
              'Participating alliance partners give your friends or family a full 20% discount on monthly premiums and one-time costs when you sign a new contract.'
            }
          />
        </StyledDescription>
      </ModalContainer>
    </Modal>
  );
};
