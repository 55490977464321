import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Radio,
  styled,
  Typography,
} from '@mui/material';

export const ModalContainer = styled(Box)`
  width: 400px;
  top: 50%;
  left: 50%;
  max-height: 66%;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 20px 20px 10px 22.3px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;

  ${props => props.theme.breakpoints.down('lg')} {
    width: 80%;
    max-height: 80%;
    padding: 20px 20px 30px 22.3px;
  }
`;
export const CloseButton = styled(IconButton)`
  align-self: flex-start;
  position: absolute;
  top: 20px;
  right: 16px;
  width: 30px;
  height: 30px;
`;

export const StyledHeader = styled(Typography)`
  color: #00194d;
  font-family: NutmegHeadline-Ultra;
  font-size: 20px;
  text-transform: uppercase;
  
  line-height: 1.2;
  padding-top: 14px;
  padding-left: 10px;
  margin-bottom: 15px;
`;
export const StyledDescription = styled(Typography)`
  font-family: Roboto;
  font-size: 12px;
  color: #1f2126;
  width: 330px;
  padding-left: 10px;
  margin-bottom: 20px;
  letter-spacing: 0.11px;
  line-height: 1.5;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  margin-bottom: 25px;
  margin-top: 20px;
  padding-left: 10px;
`;

export const ExportSection = styled('div')`
  display: flex;
  padding-left: 10px;
  margin-bottom: 10px;
`;

export const RadioButton = styled(Radio)`
  &.Mui-checked {
    color: #00194d;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: #002a80;
    opacity: 1;
  }
  margin-left: 40px;

  &.Mui-disabled {
    color: #9e9e9e;
  }

  &.MuiButtonBase-root.MuiCheckbox-root {
    margin-left: 30px;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 12px;
  }
`;
