import React, { useEffect, useState } from 'react';
import Map from '../components/GoogleMap/Map';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import { PageSelectors } from '../store/page/page.selectors';
import { PageActions } from '../store/page';
import headerDesktop from '../data/page/header_desktop.json';
import headerMobile from '../data/page/header_mobile.json';
import { Header } from '../components/Header/Header';
import { StudioActions } from '../store/studio';
import categoriesDe from '../data/partners/categories/de/partner_categories.json';
import categoriesEn from '../data/partners/categories/en/partner_categories.json';
import equipmentAndServicesDe from '../data/partners/de/equipment_and_services.json';
import equipmentAndServicesEn from '../data/partners/en/equipment_and_services.json';
import { getLanguage } from '../utils/language.utils';
import { useGetShareResultsByIdQuery } from '../store/api/shareResults/shareResultsApi';
import { useNavigate, useParams } from 'react-router-dom';
import { AutocompleteOptionType } from '../models/AutocompleteOption';
import { isLessThan30Days } from '../utils/results.utils';
import { RoutePaths } from '../RoutePaths';
import useAllVisibleStudios from '../hooks/useAllVisibleStudios';

const Explorer = () => {
  const navigate = useNavigate();
  const language = getLanguage();
  const { data: allVisibleStudios = [] } = useAllVisibleStudios();

  const isClient = process.env.REACT_APP_TYPE === 'client';
  const isSales = process.env.REACT_APP_TYPE === 'sales';
  const dispatch = useDispatch();
  const desktopMenu = useSelector(PageSelectors.getDesktopMenu);
  const mobileMenu = useSelector(PageSelectors.getMobileMenu);
  const params = useParams<{ id: string }>();
  const sharedResults = useGetShareResultsByIdQuery({ id: params.id! }, { skip: !params.id });
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    dispatch(StudioActions.setVisibleStudios(allVisibleStudios));
  }, [allVisibleStudios, dispatch]);

  useEffect(() => {
    if (!desktopMenu) {
      dispatch(PageActions.setDesktopMenu(headerDesktop));
    }

    if (!mobileMenu) {
      dispatch(PageActions.setMobileMenu(headerMobile));
    }
  }, [desktopMenu, dispatch, mobileMenu]);

  useEffect(() => {
    const categories = language === 'de' ? categoriesDe : categoriesEn;
    const equipmentAndServices =
      language === 'de' ? equipmentAndServicesDe : equipmentAndServicesEn;

    dispatch(StudioActions.setCategories(categories));
    dispatch(StudioActions.setEquipmentAndServices(equipmentAndServices));
  }, [dispatch, language]);

  useEffect(() => {
    if (isClient && sharedResults.isSuccess) {
      const {
        data: {
          timestamp,
          studios: { inputInformation, filter, studioGroups, radiusFilterValue },
        },
      } = sharedResults;

      dispatch(StudioActions.setInputInformation(inputInformation));
      dispatch(StudioActions.setFilter(filter));
      dispatch(StudioActions.setStudioGroups(studioGroups));
      dispatch(StudioActions.setRadiusFilterValue(radiusFilterValue));
      dispatch(
        StudioActions.filterVisibleStudioGroupItems({
          inputInformation,
          type: AutocompleteOptionType.Prediction,
          filter,
          radius: radiusFilterValue,
          allVisibleStudios,
        }),
      );

      if (isLessThan30Days(timestamp)) {
        setShowMap(true);
      } else {
        navigate(RoutePaths.NotFound);
      }
    }

    if (isClient && sharedResults.isError) {
      navigate(RoutePaths.NotFound);
    }

    if (isSales) {
      setShowMap(true);
    }
  }, [
    dispatch,
    sharedResults.isSuccess,
    sharedResults,
    isClient,
    isSales,
    navigate,
    allVisibleStudios,
  ]);

  useEffect(() => {
    const sidebarOpen = localStorage.getItem('sidebar-open');
    const open = typeof sidebarOpen !== 'object';

    dispatch(PageActions.toggleSidebar(open));
  }, [dispatch]);

  return (
    <>
      <Header />
      {showMap && <Map />}
    </>
  );
};
export default Explorer;
