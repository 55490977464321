import { Box, Button, IconButton, styled, TextField, Typography } from '@mui/material';

export const ModalContainer = styled(Box)`
  width: 400px;
  top: 50%;
  left: 50%;
  max-height: 66%;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 36px 20px 36px 30px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const CloseButton = styled(IconButton)`
  align-self: flex-start;
  position: absolute;
  top: 15px;
  right: 3px;
  width: 30px;
  height: 30px;
  border-radius: 6px;
`;

export const StyledHeader = styled(Typography)`
  color: #00194d;
  font-family: NutmegHeadline-Ultra;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1;
`;

export const StyledDescription = styled(Typography)`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #1f2126;
  width: 300px;
  line-height: 1.5;
  margin-top: 12px;
`;

export const CopyLinkButton = styled(Button)`
  background-color: #013bb3;
  border-radius: 18px;
  font-family: Roboto-Medium;
  font-size: 14px;
  padding: 11px 16px;
  line-height: 1;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 20px;
`;

export const InfoText = styled(Typography)`
  font-family: Roboto-Regular;
  font-size: 10px;
  color: #1f2126;
  letter-spacing: 0.09px;
`;

export const CopyIcon = styled('img')``;

export const InfoContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  width: 183px;
  padding-top: 2px;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 26px;
  label {
    font-size: 12px;
    color: #6b7986;
    font-family: Roboto-Regular;
    padding-top: 3px;
  }
  legend {
    font-size: 9px;
  }
  input {
    padding: 11.5px 14px;
    font-size: 12px;
    ::placeholder {
      color: #6b7986;
      font-family: Roboto-Regular;
      opacity: 1;
    }
  }
`;

export const StyledCopyButton = styled(Button)`
  min-width: auto;
`;
