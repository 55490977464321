export interface PageState {
  sidebar: { open: boolean };
  menu: {
    desktop: Partial<Menus> | null;
    mobile: Partial<Menus> | null;
  };
  tab: TabView;
}

export interface Menus {
  name: string;
  slug: string;
  items: Partial<Menu>[];
}

export interface Menu {
  ID: number;
  title: string;
  url: string;
  slug: string;
  child_items: Partial<Menu>[];
}

export enum TabView {
  TABLE,
  LIST,
}
