import { Slider, styled, Typography } from '@mui/material';

export const RadiusLabel = styled(Typography)`
  width: 121px;
  font-size: 12px;
  font-family: Roboto-Medium;
  color: #1f2126;
`;

export const RadiusSlider = styled(Slider)`
  color: #003bb3;
  width: 270px;
  max-width: 270px;

  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
    max-width: 100%;
    padding: 10px 0;
    flex: 1;
    margin-right: 52px;
  }
`;

export const SliderContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 12px;
  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
    align-items: start;
    margin-top: 0;
  }
`;
