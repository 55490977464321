import { Text, View } from '@react-pdf/renderer';
import { Styles } from './OnlinePlus.styles';

export interface OnlinePlusProps {
  headerText?: string;
  descriptionText?: string;
}

export const OnlinePlus = ({ headerText, descriptionText }: OnlinePlusProps) => {
  return (
    <View style={Styles.container}>
      <View style={Styles.secondSection}>
        <View style={Styles.secondInfoContainer}></View>
        {headerText && <Text style={Styles.infoHeader}>{headerText}</Text>}
        {descriptionText && <Text style={Styles.liveInfoText}>{descriptionText}</Text>}
      </View>
    </View>
  );
};
