import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import categoriesDe from '../../data/partners/categories/de/partner_categories.json';
import categoriesEn from '../../data/partners/categories/en/partner_categories.json';
import sortBy from 'lodash.sortby';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import searchMarkerIcon from '../../assets/icons/search-marker.svg';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { StudioSelectors } from '../../store/studio/studio.selectors';
import {
  Address,
  CategoryImage,
  ColumnContainer,
  Container,
  Details,
  DetailsButton,
  DistanceContainer,
  Divider,
  LeftColumn,
  MarkerImage,
  RelativeContainer,
  RightColumn,
  Row,
  StudioContainer,
  StudioName,
  Tags,
} from './StudioSearchresultslist.styles';
import {
  getCategoryById,
  getPartnerPath,
  getResolvedCategories,
} from '../../store/studio/studio.utils';
import { MapSelectors } from '../../store/map/map.selectors';
import { getLanguage } from '../../utils/language.utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { AutocompleteOptionType } from '../../models/AutocompleteOption';
import { ListData } from './interfaces';
import { StudioSortType } from '../../store/studio/interfaces';

interface SearchResultRowProps {
  index: number;
  style: CSSProperties;
}
export const StudioSearchResultsList = () => {
  const listRef = useRef<List | null>(null);
  const rowHeights = useRef<{ [key: string]: number }>({});
  const intl = useIntl();
  const language = getLanguage();
  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const studioGroups = useSelector(StudioSelectors.getStudioGroups);
  const selectedLocation = useSelector(MapSelectors.getSelectedLocation);
  const accuracy: string = 'km';
  const sort = useSelector(StudioSelectors.getSort);

  const listData: ListData[] = useMemo(() => {
    const result: ListData[] = [];

    studioGroups.forEach(studioGroup => {
      const foundInformation = inputInformation.find(
        information => information.id === studioGroup.inputInformationId,
      );

      if (foundInformation) {
        studioGroup.visibleStudios.forEach(studio => {
          const categories = getResolvedCategories(
            studio,
            language === 'de' ? categoriesDe : categoriesEn,
          );

          result.push({
            studio: {
              ...studio,
              studioName: studio.studioName.trim(),
            },
            categories,
          });
        });
      }
    });

    return sort === StudioSortType.Distance
      ? sortBy(result, ['studio.distanceFromPosition'], ['asc'])
      : sortBy(result, ['studio.studioName'], ['asc']);
  }, [inputInformation, language, sort, studioGroups]);

  const getRowHeight = (index: number) =>
    rowHeights.current[index] ? rowHeights.current[index] : 100;

  const setRowHeight = (index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const SearchResultRow = ({ index, style }: SearchResultRowProps) => {
    const allCategories = useSelector(StudioSelectors.getCategories);
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const rowRef = useRef<HTMLDivElement | null>(null);
    const listItem = listData[index];
    const { studio, categories } = listItem;
    const category = getCategoryById(allCategories, studio.categoryPrimary);

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current?.clientHeight + 1);
      }
    }, [index, rowRef]);

    const path = useMemo(() => {
      if (!studio) {
        return null;
      }

      return getPartnerPath(studio, language, allCategories);
    }, [allCategories, studio]);

    return (
      <div style={style}>
        <StudioContainer ref={rowRef}>
          <ColumnContainer>
            <Row>
              <LeftColumn>
                <CategoryImage
                  src={
                    category.iconUrlCircle
                      ? category.iconUrlCircle
                      : process.env.REACT_APP_ICON_URL_CIRCLE_FALLBACK
                  }
                  alt='Fitness'
                />
              </LeftColumn>
              <RightColumn>
                <StudioName
                  href={`${process.env.REACT_APP_HANSEFIT_WEBSITE}${path}`}
                  target={isLarge ? '_self' : '__blank'}
                  rel='noopener noreferrer'>
                  {studio.studioName}
                </StudioName>
                <Address>
                  {studio.street} {studio.streetNumber},
                </Address>
                <Address>
                  {studio.zip} {studio.city}
                </Address>
              </RightColumn>
            </Row>

            <Row>
              <LeftColumn>
                <RelativeContainer>
                  {selectedLocation &&
                    selectedLocation.type !== AutocompleteOptionType.Studio &&
                    (studio.distanceFromPosition || studio.distanceFromPosition === 0) && (
                      <DistanceContainer>
                        <MarkerImage alt='' src={searchMarkerIcon} /> {studio.distanceFromPosition}{' '}
                        {accuracy}
                      </DistanceContainer>
                    )}
                </RelativeContainer>
              </LeftColumn>
              <RightColumn>
                <Details>
                  {categories && <Tags>{categories}</Tags>}
                  <DetailsButton
                    color='primary'
                    variant='contained'
                    href={`${process.env.REACT_APP_HANSEFIT_WEBSITE}${path}`}
                    //  @ts-ignore
                    target={isLarge ? '_self' : '__blank'}
                    rel='noopener noreferrer'>
                    {intl.formatMessage({
                      id: 'details_btn',
                      defaultMessage: 'Details',
                    })}
                  </DetailsButton>
                </Details>
              </RightColumn>
            </Row>
          </ColumnContainer>
        </StudioContainer>
        <Divider />
      </div>
    );
  };

  return (
    <Container>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <List
            width={width}
            height={height}
            itemSize={getRowHeight}
            itemCount={listData.length}
            ref={listRef}>
            {SearchResultRow}
          </List>
        )}
      </AutoSizer>
    </Container>
  );
};
export default StudioSearchResultsList;
