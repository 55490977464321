import { Box, IconButton, styled, Typography } from '@mui/material';

export const ModalContainer = styled(Box)`
  width: 324px;
  top: 296px;
  left: 232px;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 32px 48px 46px 30px;

  margin: 0;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const CloseButton = styled(IconButton)`
  align-self: flex-start;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
`;

export const Header = styled(Typography)`
  color: #00194d;
  font-family: NutmegHeadline-Ultra;
  font-size: 20px;
  height: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const Description = styled(Typography)`
  font-family: Roboto-Regular;
  font-size: 12px;
  color: #1f2126;
  margin-bottom: 19px;
`;

export const Subheader = styled(Typography)`
  font-family: Roboto-Medium;
  font-size: 12px;
  color: #1f2126;
  margin-bottom: 10px;
`;

export const InstructionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const FormatInstruction = styled(Typography)`
  font-family: Roboto-Regular;
  font-size: 12px;
`;

export const StyledFormatSample = styled('img')`
  width: 75px;
  height: 86px;
`;
