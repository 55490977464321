import {
  Autocomplete,
  Button,
  IconButton,
  styled,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { withTransientProps } from '../../data/theme/withTransientProps';

export const ToggleButton = styled(IconButton, withTransientProps)<{ $isOpen: boolean }>`
  height: 34px;
  width: 34px;
  position: relative;
  right: ${props => (props.$isOpen ? '-4px' : '-8px')};
  align-self: flex-start;
  justify-self: flex-end;
  ${props => (props.$isOpen ? '' : 'transform: rotate(180deg);')}
  transition: 0.5s all ease-in;

  ${props => props.theme.breakpoints.down('lg')} {
    display: none;
  }
`;

export const ToggleHeightButton = styled(IconButton, withTransientProps)<{ $isMaxHeight: boolean }>`
  height: 34px;
  width: 34px;
  position: relative;
  right: ${props => (props.$isMaxHeight ? '-4px' : '-8px')};
  align-self: flex-start;
  justify-self: flex-end;
  ${props => (props.$isMaxHeight ? 'transform: rotate(-90deg);' : 'transform: rotate(90deg);')}
  transition: 0.25s all ease-in;

  ${props => props.theme.breakpoints.down('lg')} {
    display: none;
  }
`;

export const SidebarContainer = styled('div', withTransientProps)<{
  $isOpen: boolean;
  $showList: boolean;
  $hasLocation: boolean;
  $listTabOpen: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-flow: column;
  position: absolute;
  left: ${props => (props.$isOpen ? '0' : '-444px')};
  z-index: 2;
  width: 500px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

  background: white;
  transition: left 0.5s;

  ${props => props.theme.breakpoints.up('lg')} {
    height: ${props => (props.$showList ? '100%' : props.$hasLocation ? '260px' : '220px')};
  }

  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
    position: fixed;
    top: 50px;
    height: ${props => (props.$listTabOpen ? 'calc(100vh - 102px)' : 'auto')};
  }

  ${props => props.theme.breakpoints.down('sm')} {
    height: ${props => (props.$listTabOpen ? 'calc(100vh - 90px)' : 'auto')};
  }
`;

export const FilterContainer = styled('div', withTransientProps)<{
  $isMaxHeight: boolean;
  $isClient: boolean;
  $isSales: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 19px 20px 44px 30px;
  background-color: white;

  ${props => {
    if (props.$isSales) {
      return props.$isMaxHeight ? 'height: 48px;' : 'height: 366px;';
    }

    if (props.$isClient) {
      return props.$isMaxHeight ? 'height: 48px;' : 'height: 266px;';
    }
  }};
  ${props => (props.$isMaxHeight ? 'overflow: hidden;' : 'overflow: inherit;')};
  transition: 0.1s all ease-in;
  ${props => props.theme.breakpoints.down('lg')} {
    padding: 0 16px;
  }
`;
export const FilterTitle = styled(Typography)`
  font-family: 'NutmegHeadline-Ultra', sans-serif;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  color: #00194d;
  padding-top: 20px;
  padding-bottom: 16px;
  line-height: normal;
  margin-right: 20px;

  ${props => props.theme.breakpoints.down('lg')} {
    padding-top: 12px;
    padding-bottom: 0;
  }
`;

export const FilterForm = styled('div', withTransientProps)<{
  $isOpen: boolean;
  $isMaxHeight: boolean;
}>`
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  ${props => (props.$isMaxHeight ? 'opacity: 0;' : 'opacity: 1;')}
  transition: 0.25s all ease-in;

  ${props => props.theme.breakpoints.down('lg')} {
    gap: 16px;
    margin-bottom: 12px;
  }
`;

export const FilterRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 400px;

  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
  }

  & input {
    font-family: Roboto-Regular;
    font-size: 12px;
    color: #1f2126;
    height: 24px;

    ::placeholder {
      color: #6b7986;
      opacity: 1;
    }
  }

  .MuiAutocomplete-endAdornment button {
    visibility: visible;
  }

  .MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall {
    padding-right: 8px;
  }
`;

export const FilterButton = styled(IconButton)`
  margin-left: 22px;
  position: relative;
  width: 30px;
  height: 33px;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  gap: 25px;
  margin-top: 20px;
  padding-left: 0;
  margin-right: 0;
`;

export const InfoAndSelectContainer = styled('div')`
  display: flex;
  gap: 10px;
  margin-top: 8px;
`;

export const ExportButton = styled(Button)`
  background-color: #013bb3;
  border-radius: 18px;
  font-family: Roboto-Medium;
  font-size: 15px;
  line-height: 1;
`;
export const ShareResultButton = styled(Button)`
  color: #2c58b4;
  border-color: #2c58b4;
  border-radius: 18px;
  font-size: 15px;
  line-height: 1;
  font-family: Roboto-Medium;
`;

export const FilterIcon = styled('img')`
  width: 28px;
  height: 28px;
`;

export const ActiveFilter = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e50151;
  color: white;
  width: 18px;
  height: 18px;
  font-size: 12px;
  font-family: Roboto-Regular;
  position: absolute;
  border-radius: 50%;
  left: 20px;
  top: -4px;
`;

export const InfoButton = styled(Button)`
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-family: Roboto-Regular;
  color: #003bb3;
  font-size: 12px;
  text-transform: none;
`;

export const InfoIcon = styled('img')`
  cursor: pointer;
`;

export const TopContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const FilterDescription = styled(Typography, withTransientProps)<{
  $marginBottom: string;
  $isMaxHeight: boolean;
}>`
  font-family: Roboto-Regular;
  font-size: 12px;
  letter-spacing: 0.11px;
  color: #1f2126;
  margin-bottom: ${props => props.$marginBottom};
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  ${props => (props.$isMaxHeight ? 'opacity: 0;' : 'opacity: 1;')}
  transition: 0.25s all ease-in;
`;

export const FilterClientDescription = styled(FilterDescription)`
  font-family: Roboto-Medium;
  font-weight: 500;
`;

export const MobileTabs = styled(Tabs)`
  width: 100%;
  height: 40px;
  opacity: 1;
  background-color: #f8f8f8;
  font-family: Roboto-Medium;
  font-size: 14px;

  & .MuiTabs-indicator {
    background-color: #003bb3;
  }

  & .MuiTab-root {
    color: #1f2126;
  }

  & .MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #003bb3;
    font-weight: 500;
  }
`;

export const StyledTag = styled(Typography)`
  background: white;
  font-family: Roboto-Medium;
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 12px;
  border: 1px solid #d8d8d8;

  :hover {
    cursor: pointer;
  }
`;

export const TagContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-left: 12px;
  position: relative;
  left: 20px;
`;

export const InputCtaIconContainer = styled(IconButton)`
  padding: 0;
  background: none;
`;

export const FilterHeader = styled(Typography)`
  font-family: Roboto-Medium;
  font-size: 12px;
  color: #1f2126;
`;

export const InputContainer = styled('div')`
  position: relative;
`;

export const LocationIcon = styled('img')`
  position: absolute;
  top: 12px;
  left: 12px;
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
    margin-left: 25px;
  }
`;
export const ResultFilterContainer = styled('div', withTransientProps)<{ $isMaxHeight: boolean }>`
  ${props => (props.$isMaxHeight ? 'opacity: 0;' : 'opacity: 1;')}
  transition: 0.25s all ease-in;
`;
