import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Document, Page, Font, PDFDownloadLink } from '@react-pdf/renderer';
import { Header } from './components/Header/Header';
import { Filter } from './components/Filter/Filter';
import { useSelector } from 'react-redux';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import {
  getResolvedCategories,
  getFilteredMemberships,
  getFilteredCheckIns,
} from '../FilterModal/FilterModal.utils';
import { Table } from './components/Table/Table';
import { PdfTableItem } from './interfaces';
import { getDistanceInKilometers } from '../../store/studio/studio.utils';
import { AdditionalPages } from './components/AdditionalPages/AdditionalPages';
import { useIntl } from 'react-intl';
import { getGroupedTableItems } from './components/Table/Table.utils';
import { StudioSortType } from '../../store/studio/interfaces';
import sortBy from 'lodash.sortby';

Font.register({
  family: 'NutmegHeadline-Ultra',
  fonts: [{ src: '/pdf/fonts/hansefit_nutmeg_font.ttf' }],
});

Font.register({
  family: 'Roboto-Regular',
  fonts: [{ src: '/pdf/fonts/Roboto-Regular.ttf' }],
});

Font.register({
  family: 'Roboto-Bold',
  fonts: [{ src: '/pdf/fonts/Roboto-Bold.ttf' }],
});

Font.register({
  family: 'Roboto-Medium',
  fonts: [{ src: '/pdf/fonts/Roboto-Medium.ttf' }],
});

export interface PdfDocumentProps {
  handleClose: () => void;
  additionalPages: boolean;
}

export const PdfDocument = ({ handleClose, additionalPages }: PdfDocumentProps) => {
  const intl = useIntl();
  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const studioGroups = useSelector(StudioSelectors.getStudioGroups);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const filter = useSelector(StudioSelectors.getFilter);
  const sort = useSelector(StudioSelectors.getSort);
  const allCategories = useSelector(StudioSelectors.getCategories);
  const categories = getResolvedCategories(filter.categories, allCategories).join(', ');
  const search = inputInformation.map(item => item.name).join(', ');
  const filteredMemberships = getFilteredMemberships(filter);
  const filteredCheckIns = getFilteredCheckIns(filter, intl.formatMessage);
  const filename = inputInformation.find(item => item.fileId.length > 0)?.filename;
  const tableData = useMemo(() => {
    const data: PdfTableItem[] = [];

    inputInformation.forEach(information => {
      const { lat, lng } = information;
      const groupInformation = studioGroups.find(
        group => group.inputInformationId === information.id,
      );
      data.push({
        zipCode: information.name,
        numberOfPartner: groupInformation ? groupInformation.visibleStudios.length : '-',
        name: '',
        distance: '',
        categories: '',
      });

      if (groupInformation) {
        const visibleStudios = groupInformation.visibleStudios.map(studio => ({
          ...studio,
          distanceFromPosition: getDistanceInKilometers(
            { lat: studio.coordLat, lng: studio.coordLong },
            { lat: information?.lat!, lng: information?.lng! },
          ),
        }));

        const sortedVisibleStudios =
          sort === StudioSortType.Distance
            ? sortBy(visibleStudios, ['distanceFromPosition'], ['asc'])
            : sortBy(visibleStudios, ['studioName'], ['asc']);

        sortedVisibleStudios.forEach(studio => {
          const { categories, coordLat, coordLong, studioName, categoryPrimary } = studio;

          data.push({
            zipCode: '',
            numberOfPartner: '',
            name: studioName,
            distance: `${getDistanceInKilometers(
              { lat: coordLat, lng: coordLong },
              { lat, lng },
            )} km`,
            categories: getResolvedCategories([categoryPrimary, ...categories], allCategories).join(
              ', ',
            ),
          });
        });
      }
    });

    const result = getGroupedTableItems(data);

    return {
      firstPage: result?.[0] || [],
      otherPages: result.splice(1),
    };
  }, [allCategories, inputInformation, sort, studioGroups]);

  const download = (filename: string, url: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', `${url}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const PdfDocument = () => (
    <Document>
      <Page size='A4'>
        <Header
          headerText={intl.formatMessage({
            id: 'export.pdf.header',
            defaultMessage: 'Partner search',
          })}
          descriptionText={intl.formatMessage({
            id: 'export.pdf.description',
            defaultMessage: 'Here is the result of your individual partner search.',
          })}
        />
        <Filter
          intl={intl}
          radius={radius}
          categories={categories}
          search={search}
          filteredMemberships={filteredMemberships}
          filteredCheckIns={filteredCheckIns}
          filename={filename}
        />
        {tableData.firstPage && <Table intl={intl} data={tableData.firstPage} />}
      </Page>
      {tableData.otherPages &&
        tableData.otherPages.map(data => (
          <Page wrap={true} size='A4' key={uuidv4()} break={false}>
            <Header />
            <Table intl={intl} data={data} />
          </Page>
        ))}
      {additionalPages && <AdditionalPages intl={intl} />}
    </Document>
  );

  const PdfLink = () => {
    const filename = `vp-explorer-export-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.pdf`;
    return (
      <PDFDownloadLink fileName={filename} document={<PdfDocument />}>
        {({ blob, url, loading, error }) => {
          if (!loading && blob) {
            handleClose();
            download(filename, URL.createObjectURL(blob));
          }
          return <></>;
        }}
      </PDFDownloadLink>
    );
  };

  return (
    <>
      <PdfLink />
    </>
  );
};
