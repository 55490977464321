import { Navigate, Route, Routes } from 'react-router-dom';
import Explorer from './pages/Explorer';
import NotFound from './components/NotFound/NotFound';
import { RoutePaths } from './RoutePaths';
import { AuthProvider } from './contexts/auth';
import RequireAuth from './components/RequireAuth';
import Login from './pages/Login';
import SignIn from './pages/SignIn';
import Logout from './pages/Logout';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

const overwrittenConfig = {
  ...awsconfig,
  oauth: {
    ...(awsconfig.oauth || {}),
    redirectSignIn: window.location.origin + '/signin',
    redirectSignOut: window.location.origin + '/signout',
    responseType: 'code',
  },
};

Amplify.configure(overwrittenConfig);

const queryClient = new QueryClient();

const App = () => {
  if (process.env.REACT_APP_TYPE === 'sales') {
    return (
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              path={RoutePaths.Root}
              element={
                <RequireAuth>
                  <Explorer />
                </RequireAuth>
              }
            />
            <Route path='/login' element={<Login />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/logout' element={<Logout />} />

            <Route path={RoutePaths.NotFound} element={<NotFound />} />
            <Route path='*' element={<Navigate to={RoutePaths.NotFound} />} />
          </Routes>
        </QueryClientProvider>
      </AuthProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path={RoutePaths.Share} element={<Explorer />} />
        <Route path={RoutePaths.NotFound} element={<NotFound />} />
        <Route path='*' element={<Navigate to={RoutePaths.NotFound} />} />
      </Routes>
    </QueryClientProvider>
  );
};

export default App;
