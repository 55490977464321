import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import Sidebar from '../Sidebar/Sidebar';
import SelectedStudioModal from '../SelectedStudioModal/SelectedStudioModal';
import { Container } from './Map.styles';
import LocationMarkers from './LocationMarkers';
import useAllVisibleStudios from '../../hooks/useAllVisibleStudios';
import CustomMarker from './CustomMarker';
import { MapSelectors } from '../../store/map/map.selectors';
import { getFilteredStudios } from '../../store/studio/studio.utils';
import { SpinnerLoader } from '../SpinnerLoader/SpinnerLoader';

const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  zoomControlOptions: { position: 9 },

  minZoom: 5,
  maxZoom: 18,

  options: {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  },
};

const containerStyles = {
  width: '100%',
  height: '100%',
  overflow: 'scroll',
};
const defaultZoom = 7;
let defaultCenter = {
  lat: 50.714504,
  lng: 10.101328,
};

export const Map = () => {
  const isMobileView = window.innerWidth <= 768;
  const selectedStudioModalOpen = useSelector(StudioSelectors.getSelectedStudioModalOpen);
  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const filter = useSelector(StudioSelectors.getFilter);
  const [map, setMap] = useState<google.maps.Map>();
  const studioGroups = useSelector(StudioSelectors.getStudioGroups);
  const { data: allVisibleStudios = [], isLoading } = useAllVisibleStudios();
  const selectedStudios = useSelector(StudioSelectors.getSelectedStudios);
  const highlightedStudio = useSelector(StudioSelectors.getHighlightedStudios);
  const selectedLocation = useSelector(MapSelectors.getSelectedLocation);

  const allVisibleStudiosForMarker = useMemo(
    () =>
      getFilteredStudios({
        studios: allVisibleStudios ?? [],
        filter,
        radius,
      }),
    [allVisibleStudios, filter, radius],
  );
  const [isMounted, setIsMounted] = useState(false);

  const onLoad = useCallback((map: google.maps.Map) => {
    map.setCenter(defaultCenter);
    map.setZoom(defaultZoom);
    setMap(map);
  }, []);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (inputInformation.length > 0) {
      const latAdjustment = 0;
      const lngAdjustment = 0;
      const bounds = new window.google.maps.LatLngBounds();
      inputInformation.forEach(item => {
        const foundStudioGroup = studioGroups.find(group => group.inputInformationId === item.id);

        if (foundStudioGroup) {
          foundStudioGroup.visibleStudios.forEach(studio => {
            if (!!studio.coordLat && !!studio.coordLong) {
              bounds.extend(
                new window.google.maps.LatLng(
                  studio.coordLat + latAdjustment,
                  studio.coordLong - lngAdjustment,
                ),
              );
            }
          });
        }

        if (!!item.lat && !!item.lng) {
          const { lat, lng } = item;

          bounds.extend(new window.google.maps.LatLng(lat, lng));
        }
      });

      if (!bounds.isEmpty()) {
        map?.setCenter(bounds.getCenter());
        map?.fitBounds(bounds);
      }
    }
  }, [inputInformation, map, studioGroups]);

  const hover = useSelector(StudioSelectors.getHover, () =>
    allVisibleStudios ? allVisibleStudios.length > 200 && !selectedLocation : false,
  );

  return (
    <Container>
      {isLoading && <SpinnerLoader />}
      <GoogleMap mapContainerStyle={containerStyles} options={mapOptions} onLoad={onLoad} id='map'>
        {isMounted ? (
          <>
            {allVisibleStudiosForMarker.map((studio, idx) => {
              const isHoverOrSelect =
                selectedStudios.includes(studio.publicId) || hover === studio.publicId;
              const isHighlighted = highlightedStudio.includes(studio.publicId);

              return (
                <CustomMarker
                  key={`${studio.publicId}-studio`}
                  studio={studio}
                  idx={idx}
                  isHoverOrSelect={isHoverOrSelect}
                  isHighlighted={isHighlighted}
                />
              );
            })}

            {inputInformation.map(item => (
              <LocationMarkers
                id={item.id}
                lng={item.lng}
                lat={item.lat}
                key={`${item.id}-marker`}
                radius={radius}
              />
            ))}
          </>
        ) : null}
        <Sidebar />
        {isMobileView && selectedStudioModalOpen && <SelectedStudioModal />}
      </GoogleMap>
    </Container>
  );
};

export default Map;
