import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';

import {
  InfoTextContainer,
  MemberContainer,
  MembershipIconContainer,
  MembershipIcon,
  MemberInformationContainerBest,
  MemberInformationContainerPro,
  MemberInformationContainerBasic,
} from './MembershipModal.styles';
import filtersModalIcon from '../../assets/icons/close.svg';
import { membershipImageBasePath } from '../../models/user_membership';
import { Divider, Modal } from '@mui/material';
import {
  CloseButton,
  ModalContainer,
  StyledDescription,
  StyledHeader,
} from './InformationModal.styles';

export interface MembershipModalProps {
  handleClose: () => void;
}

export const MembershipModal = ({ handleClose }: MembershipModalProps) => {
  const intl = useIntl();

  return (
    <Modal open={true}>
      <ModalContainer>
        <CloseButton onClick={handleClose}>
          <img src={filtersModalIcon} alt="" />
        </CloseButton>
        <StyledHeader>
          <FormattedMessage
            id={'filters.membership.information.header'}
            defaultMessage={'Hansefit Memberships'}
          />
        </StyledHeader>
        <StyledDescription>
          <FormattedMessage
            id={'filters.membership.information.subheader'}
            defaultMessage={
              'The membership is determined by your employer and regulates which Hansefit offers you can use free of charge.'
            }
          />
        </StyledDescription>

        <MemberContainer>
          <MembershipIconContainer>
            <MembershipIcon src={membershipImageBasePath + 'hf_best.png'} alt={'Membership'} />
            Best
          </MembershipIconContainer>
          <MemberInformationContainerBest>
            {intl.formatMessage({
              id: 'filters.membership.information.best',
              defaultMessage:
                'You can check in with all Hansefit alliance partners without limits and participate in our online courses. You also have access to numerous online offers under the "Online+" tab.',
            })}
          </MemberInformationContainerBest>
        </MemberContainer>
        <MemberContainer>
          <MembershipIconContainer>
            <MembershipIcon src={membershipImageBasePath + 'hf_pro.png'} alt={'Membership'} />
            Pro
          </MembershipIconContainer>
          <MemberInformationContainerPro>
            {intl.formatMessage({
              id: 'filters.membership.information.pro',
              defaultMessage:
                'You can check-in to all gyms and swimming pools in the Hansefit network without limits and participate in our online courses.',
            })}
          </MemberInformationContainerPro>
        </MemberContainer>
        <MemberContainer>
          <MembershipIconContainer>
            <MembershipIcon src={membershipImageBasePath + 'hf_basic.png'} alt={'Membership'} />
            Basic
          </MembershipIconContainer>
          <MemberInformationContainerBasic>
            {intl.formatMessage({
              id: 'filters.membership.information.basic',
              defaultMessage:
                'You can check in up to four times a month at a gym from the Hansefit network or participate in one of our online classes.',
            })}
          </MemberInformationContainerBasic>
        </MemberContainer>
        <Divider />
        <InfoTextContainer>
          {intl.formatMessage({
            id: 'filters.membership.information.info',
            defaultMessage:
              'Info text for Classic:Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
          })}
        </InfoTextContainer>
      </ModalContainer>
    </Modal>
  );
};
