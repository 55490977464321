import React, { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StudioSelectors } from '../../store/studio/studio.selectors';

import { Container, StyledTab, StyledTabs, TabsContainer } from './Selection.styles';
import NoResults from '../NoResults/NoResults';
import { StudioSearchResultsTable } from '../StudioSearchResults/StudioSearchResultsTable';
import { PageSelectors } from '../../store/page/page.selectors';
import { TabView } from '../../store/page/interfaces';
import { PageActions } from '../../store/page';
import StudioSearchResultsList from '../StudioSearchResults/StudioSearchResultsList';
import { hasAnyResult } from '../../utils/results.utils';
import { useIntl } from 'react-intl';
import { Box, FormControl, MenuItem, Radio, Select, SelectChangeEvent } from '@mui/material';
import sortingIcon from '../../assets/icons/sorting.svg';
import { StudioSortType } from '../../store/studio/interfaces';

import { StudioActions } from '../../store/studio';

const Selection = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const tab = useSelector(PageSelectors.getTab);
  const studioGroups = useSelector(StudioSelectors.getStudioGroups);
  const sort = useSelector(StudioSelectors.getSort);

  const handleTab = (_: SyntheticEvent<Element, Event>, value: TabView) => {
    dispatch(PageActions.setTab(value));
  };

  const handleSortFilterChange = (event: SelectChangeEvent) => {
    const sort = event.target.value as unknown as StudioSortType;
    dispatch(StudioActions.setSort(sort));
  };

  if (!hasAnyResult(studioGroups)) {
    return <NoResults />;
  }

  return (
    <Container>
      <TabsContainer>
        <StyledTabs value={tab} onChange={handleTab}>
          <StyledTab
            label={intl.formatMessage({
              id: 'selection.tab.table_view',
              defaultMessage: 'Table view',
            })}
            value={TabView.TABLE}
          />
          <StyledTab
            label={intl.formatMessage({
              id: 'selection.tab.list_view',
              defaultMessage: 'List view',
            })}
            value={TabView.LIST}
          />
        </StyledTabs>

        <FormControl
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <Box sx={{ marginRight: '6px' }}>
            <img src={sortingIcon} alt='Sorting' />
          </Box>
          <Select
            variant='standard'
            value={sort as unknown as string}
            renderValue={value =>
              (value as unknown as StudioSortType) === StudioSortType.Distance
                ? intl.formatMessage({ id: 'sort.distance', defaultMessage: 'Distance' })
                : intl.formatMessage({ id: 'sort.alphabetical', defaultMessage: 'A - Z' })
            }
            onChange={handleSortFilterChange}>
            <MenuItem value={StudioSortType.Distance} key={StudioSortType.Distance}>
              <Radio checked={sort === StudioSortType.Distance} size='small' />
              {intl.formatMessage({ id: 'sort.distance', defaultMessage: 'Distance' })}
            </MenuItem>
            <MenuItem value={StudioSortType.Alphabetical} key={StudioSortType.Alphabetical}>
              <Radio checked={sort === StudioSortType.Alphabetical} size='small' />
              {intl.formatMessage({ id: 'sort.alphabetical', defaultMessage: 'A - Z' })}
            </MenuItem>
          </Select>
        </FormControl>
      </TabsContainer>

      {tab === TabView.TABLE && <StudioSearchResultsTable />}
      {tab === TabView.LIST && <StudioSearchResultsList />}
    </Container>
  );
};

export default Selection;
