import { Category } from '../../models/Category';
import { CheckInOption } from '../../models/CheckIn';
import { StudioFilter } from '../../store/studio/interfaces';
import { UserMemberships } from '../../models/user_membership';
import { IntlShape } from 'react-intl';

export const getResolvedCheckIns = (
  checkIns: string[],
  formatMessage: IntlShape['formatMessage'],
) =>
  checkIns.map(checkIn => {
    switch (checkIn) {
      case CheckInOption.App: {
        return formatMessage({
          id: 'check_in.app',
          defaultMessage: 'Check-in with app',
        });
      }
      case CheckInOption.Card: {
        return formatMessage({
          id: 'check_in.card',
          defaultMessage: 'Check-in with card',
        });
      }

      case CheckInOption.Issuing: {
        return formatMessage({
          id: 'check_in.issuing',
          defaultMessage: 'Issuing of membership cards',
        });
      }
      case CheckInOption.Twogether: {
        return formatMessage({
          id: 'check_in.twogether',
          defaultMessage: 'Twogether training program',
        });
      }
      default: {
        return checkIn;
      }
    }
  });

export const getResolvedCategories = (selectedCategories: number[], categories: Category[]) =>
  selectedCategories.map(categoryId => {
    const foundCategory = categories.find(category => category.id === categoryId);
    if (foundCategory) {
      return foundCategory.shortDescription as string;
    }
    return '';
  });

export const getFilteredMemberships = (filter: StudioFilter) => {
  if (filter.servicePackages.includes(UserMemberships.basic)) {
    return 'Basic';
  }
  if (filter.servicePackages.includes(UserMemberships.pro)) {
    return 'Pro, Basic';
  }

  if (filter.servicePackages.includes(UserMemberships.best)) {
    return 'Best, Pro, Basic';
  }

  return '-';
};

export const getSelectValue = (
  selectedValues: string[],
  maxLength: number,
  additionalTranslation: string,
) => {
  const sumLength = selectedValues.join(', ').trim().length;

  if (sumLength < maxLength) {
    return selectedValues.join(', ').trim();
  } else {
    const outputArray: string[] = [];

    selectedValues.every(category => {
      if (category && outputArray.join(', ').length + category?.length < maxLength) {
        outputArray.push(category);
      } else {
        return false;
      }

      return true;
    });

    const delta = selectedValues.length - outputArray.length;
    if (delta > 0) {
      return `${outputArray.join(', ').trim()} + ${delta} ${additionalTranslation}`.trim();
    } else {
      return outputArray.join(', ').trim();
    }
  }
};

export const getFilteredCheckIns = (
  filter: StudioFilter,
  formatMessage: IntlShape['formatMessage'],
) => {
  const unresolvedCheckIns: string[] = [];

  if (filter.checkInApp) {
    unresolvedCheckIns.push(CheckInOption.App);
  }

  if (filter.checkInCard) {
    unresolvedCheckIns.push(CheckInOption.Card);
  }

  if (filter.hansefitCard) {
    unresolvedCheckIns.push(CheckInOption.Issuing);
  }
  if (filter.servicePackages.includes(18)) {
    unresolvedCheckIns.push(CheckInOption.Twogether);
  }

  return getResolvedCheckIns(unresolvedCheckIns, formatMessage).join(', ');
};
