import { PageState, TabView } from './interfaces';

export const initialState: PageState = {
  sidebar: { open: true },
  menu: {
    desktop: null,
    mobile: null,
  },
  tab: TabView.TABLE,
};
